import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type UpdateListingPropertyDetailsRequest = {
  listingId: string;
  changes: {
    bedrooms?: number;
    bathrooms?: number;
    floorArea?: number;
    landArea?: number;
  };
};

export async function updateListingPropertyDetails(
  request: UpdateListingPropertyDetailsRequest
): Promise<void> {
  try {
    await apiClient.patch(
      `listing/${request.listingId}/property-details`,
      request.changes
    );
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
