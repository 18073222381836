import { FormEvent, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import {
  getAccountDetails,
  login,
  LoginAccountResponse,
  LoginGoogleResponse,
  loginWithGoogle,
} from '../../api';
import { useSessionContext } from '../../contexts/SessionContext';

export const Login: React.VFC = () => {
  const [session, setSession] = useSessionContext();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('account_details');

    setSession((s) => ({
      ...s,
      isAuthenticated: false,
      accountDetails: undefined,
    }));
  };

  const handleLoginResult = async (
    loginResult: LoginAccountResponse | LoginGoogleResponse
  ) => {
    if (loginResult.success) {
      localStorage.setItem('access_token', loginResult.result.accessToken);

      const accountDetails = await getAccountDetails();
      localStorage.setItem('account_details', JSON.stringify(accountDetails));

      setSession((s) => ({
        ...s,
        isAuthenticated: true,
        accountDetails,
      }));

      ReactGA.set({
        userId: accountDetails.userId,
      });
    } else {
      setError(loginResult.errorMessage);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setError(undefined);

    logout();

    ReactGA.event({
      category: 'Auth',
      action: 'Login Clicked',
    });

    const username = (
      (event.target as HTMLFormElement).elements.namedItem(
        'username'
      ) as HTMLInputElement
    ).value;
    const password = (
      (event.target as HTMLFormElement).elements.namedItem(
        'password'
      ) as HTMLInputElement
    ).value;

    try {
      const loginResult = await login(username, password);
      await handleLoginResult(loginResult);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async (response: GoogleLoginResponse) => {
    setLoading(true);
    setError(undefined);

    ReactGA.event({
      category: 'Auth',
      action: 'Google Login Clicked',
    });

    try {
      const loginResult = await loginWithGoogle(response.accessToken);
      await handleLoginResult(loginResult);
    } finally {
      setLoading(false);
    }
  };

  if (session.isAuthenticated && session.accountDetails != null) {
    if (session.accountDetails.userDetails.emailVerified === false) {
      return <Redirect to={{ pathname: '/verify-email' }} />;
    }
    if (session.accountDetails.userDetails.mobile == null) {
      return <Redirect to={{ pathname: '/provide-mobile' }} />;
    }
    if (session.accountDetails.userDetails.mobileVerified === false) {
      return <Redirect to={{ pathname: '/verify-mobile' }} />;
    }

    return <Redirect to={session.redirectPath} />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-72 pt-20">
        <form onSubmit={onSubmit}>
          <label htmlFor="username" className="block m-2">
            Email Address
            <input
              id="username"
              name="username"
              type="email"
              className="p-1 border block w-full"
              disabled={loading}
            />
          </label>
          <label htmlFor="password" className="block m-2">
            Password
            <input
              id="password"
              name="password"
              type="password"
              className="p-1 border block w-full"
              disabled={loading}
            />
          </label>
          {error && (
            <div className="p-2 m-2 bg-red-400 text-white">{error}</div>
          )}
          <div className="m-2">
            <button
              type="submit"
              className="block bg-gray-700 text-white w-full p-2"
              disabled={loading}
            >
              Login
            </button>
          </div>
        </form>

        <div className="m-2 mt-5 flex flex-col">
          <div className="flex items-center">
            <div className="border-b flex-1" />
            <div className="p-2 text-gray-500 -mt-1">or</div>
            <div className="border-b flex-1" />
          </div>
          <GoogleLogin
            scope={[
              'https://www.googleapis.com/auth/userinfo.email',
              'https://www.googleapis.com/auth/userinfo.profile',
              'https://www.googleapis.com/auth/user.phonenumbers.read',
              'openid',
            ].join(' ')}
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            buttonText="Log in with Google"
            onRequest={() => setLoading(true)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess={handleGoogleLogin as any}
            onFailure={() => {
              setError('Error signing in with Google');
              setLoading(false);
            }}
          />
          <div className="text-gray-500 text-center m-5">
            Not a member?{' '}
            <Link
              to="/register"
              className="text-gray-900 hover:text-blue-500 underline"
            >
              Sign up now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
