import { XIcon } from '@heroicons/react/outline';

export type DialogProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
};

export const Dialog: React.VFC<DialogProps> = ({
  children,
  open,
  onClose,
}: DialogProps) => {
  if (!open) {
    return <></>;
  }

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-70 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>{children}</div>

        <span className="absolute top-0 right-0 p-4">
          <button
            type="button"
            title="Close"
            className="cursor-pointer hover:text-blue-500 h-full w-10 flex justify-center items-center"
            onClick={() => onClose()}
          >
            <XIcon height={24} />
          </button>
        </span>
      </div>
    </div>
  );
};
