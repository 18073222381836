export class ValidationError extends Error {
  public isValidationError = true;

  public constructor(
    message: string,
    public errors: { [key: string]: string[] }
  ) {
    super(message);
  }
}
