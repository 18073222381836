import { RegisterAccountSuccessResult } from './api-types';
import { ValidationError } from './common';

const errorMessages = new Map<number, string>([
  [500, 'An unexpected error occurred'],
]);

export async function register(
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<RegisterAccountSuccessResult> {
  let response: Response;

  try {
    response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
          firstName,
          lastName,
        }),
      }
    );

    if (response.status === 201) {
      return await response.json();
    }
  } catch (ex) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      errorMessages.get(500)!
    );
  }

  if (response.status === 400) {
    const body = await response.json();
    throw new ValidationError(
      'Invalid Request',
      body.errors || body.value.errors
    );
  }

  if (response.status !== 201) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      errorMessages.get(response.status) ?? errorMessages.get(500)!
    );
  }

  throw new Error(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    errorMessages.get(500)!
  );
}
