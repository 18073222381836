import React from 'react';
import { Route } from 'react-router-dom';
import { Login } from './Login';
import { ProvideMobile } from './ProvideMobile';
import { Register } from './Register';
import { VerifyEmail } from './VerifyEmail';
import { VerifyMobile } from './VerifyMobile';

export const authRoutes: React.ReactNode[] = [
  <Route path="/login" component={Login} />,
  <Route path="/register" component={Register} />,
  <Route path="/verify-email" component={VerifyEmail} />,
  <Route path="/provide-mobile" component={ProvideMobile} />,
  <Route path="/verify-mobile" component={VerifyMobile} />,
];
