import { XIcon } from '@heroicons/react/outline';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getListingDetails,
  updateListingDetails,
  UpdateListingDetailsRequestChanges,
} from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { TextAlert } from '../../../components/TextAlert';
import { Form, FormData } from './Form';

export const Layout: React.VFC = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const listingQuery = useQuery(['listing-details', listingId], () =>
    getListingDetails(listingId)
  );
  const [formData, setFormData] = useState<FormData>({
    listingTitle: '',
  });
  const [formDataChanges, setFormDataChanges] = useState<
    Map<keyof FormData, string>
  >(new Map());
  const hasFormChanges = useMemo(
    () => formDataChanges.size > 0,
    [formDataChanges]
  );
  const [error, setError] = useState<string>();

  const onFormChangesChanged = useCallback(
    (value: Map<keyof FormData, string>): void => setFormDataChanges(value),
    []
  );

  const onFormDataChanged = useCallback(
    (value: FormData): void => setFormData(value),
    []
  );

  if (listingQuery.isLoading) {
    return <span>Loading...</span>;
  }

  if (listingQuery.isError) {
    return (
      <TextAlert
        label={
          (listingQuery.error as Error).message ||
          'Unexpected error occurred loading the listing'
        }
      />
    );
  }

  const onCancelClicked = () => {
    setFormData({
      listingTitle: listingQuery.data?.listingTitle || '',
    });
  };

  const onSaveClicked = async () => {
    setError(undefined);

    const changes: UpdateListingDetailsRequestChanges = Object.fromEntries(
      formDataChanges.entries()
    );
    changes.listingTitle = formData.listingTitle || null;

    try {
      await updateListingDetails({
        listingId,
        changes,
      });
      await listingQuery.refetch();
    } catch {
      setError('Error updating listing details.');
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            route: `/listings/${listingId}`,
            label:
              listingQuery.data != null
                ? listingQuery.data.property.propertyAddress
                : 'Listing Details',
          },
          { label: 'Edit Listing Details' },
        ]}
      />

      <div className="bg-white">
        <Heading title="Edit Listing Details">
          {hasFormChanges && (
            <>
              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onCancelClicked}
              >
                Reset
              </button>

              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onSaveClicked}
              >
                Save
              </button>
            </>
          )}
        </Heading>

        {error && (
          <div className="flex text-red-500 bg-red-200 m-3 border-red-400 border p-4">
            <div className="flex-1">{error}</div>
            <button
              type="button"
              title="Clear error"
              className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
              onClick={() => setError(undefined)}
            >
              <XIcon height={24} />
            </button>
          </div>
        )}

        {listingQuery.data != null && (
          <div className="p-8">
            <Form
              listing={listingQuery.data}
              formData={formData}
              onFormDataChange={onFormDataChanged}
              onFormChangesChange={onFormChangesChanged}
            />
            {/* <div>formData: {JSON.stringify(formData, undefined, 2)}</div> */}
          </div>
        )}
      </div>
    </>
  );
};
