/* eslint-disable jsx-a11y/img-redundant-alt */
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getAccountDetails } from '../../api';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { DescriptionList } from '../../components/DescriptionList';
import { Heading } from '../../components/Heading';
import { TextAlert } from '../../components/TextAlert';

export const AccountDetails: React.VFC = () => {
  const { isLoading, isError, error, data } = useQuery(
    ['account-details'],
    () => getAccountDetails()
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return (
      <TextAlert
        label={
          (error as Error).message ||
          'Unexpected error occurred loading your account'
        }
      />
    );
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'My Account' }]} />

      <Heading title="My Account" />

      {data && (
        <div className="bg-white border">
          <div className="p-4" style={{ width: 200 }}>
            <img src={data.avatarUrl || ''} width={200} alt="My Photo" />
            <Link
              to="/account/edit-photo"
              className="block bg-gray-700 text-white w-full p-2 text-center"
            >
              Change
            </Link>
          </div>

          <div className="border-t border-gray-200 w-full max-w-lg">
            <DescriptionList
              items={[
                { label: 'Account Type', value: data.accountType },
                {
                  label: 'Name',
                  value: `${data.userDetails.firstName} ${data.userDetails.lastName}`,
                },
                {
                  label: 'Mobile',
                  value: data.userDetails.mobile,
                },
                {
                  label: 'Email',
                  value: data.userDetails.email,
                },
                {
                  label: 'Address',
                  value: data.userDetails.address || '',
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};
