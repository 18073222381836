import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentIcon, QrcodeIcon, XIcon } from '@heroicons/react/outline';
import { useMemo, useState } from 'react';
import { formatDistance, parseISO } from 'date-fns';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import {
  getListingDetails,
  ListingStatus,
  publishListing,
  deleteListing,
  AccountType,
  republishListingPoster,
} from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { DescriptionList } from '../../../components/DescriptionList';
import { TextAlert } from '../../../components/TextAlert';
import { DialogConfirm } from '../../../components/DialogConfirm';
import { useSessionContext } from '../../../contexts/SessionContext';

const numberFormat = Intl.NumberFormat();

const getDocumentSizeString = (value: number): string => {
  if (value < 1024) {
    return `${value} B`;
  }

  return `${numberFormat.format(Math.ceil(value / 1024))} KB`;
};

export const ListingDetails: React.VFC = () => {
  const history = useHistory();
  const { listingId } = useParams<{ listingId: string }>();
  const [session] = useSessionContext();
  const republishListingPosterMutation = useMutation(() =>
    republishListingPoster({ listingId })
  );
  const listingQuery = useQuery(['listing-details', listingId], () =>
    getListingDetails(listingId)
  );
  const documentSizes = useMemo(() => {
    const sizes = new Map<string, string>();

    if (listingQuery.data) {
      listingQuery.data.documents.forEach((d) => {
        sizes.set(d.documentId, getDocumentSizeString(d.documentSize));
      });
    }

    return sizes;
  }, [listingQuery.data]);
  const listingAge = useMemo(() => {
    if (listingQuery.data && listingQuery.data.publishedAt) {
      const toDate = listingQuery.data.closedAt
        ? parseISO(listingQuery.data.closedAt)
        : new Date();
      return formatDistance(toDate, parseISO(listingQuery.data.publishedAt));
    }
    return undefined;
  }, [listingQuery.data]);
  const [error, setError] = useState<string>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  const onConfirmDelete = async () => {
    setError(undefined);

    try {
      await deleteListing(listingId);
    } catch {
      setError('Error deleting the listing');
      return;
    }

    listingQuery.remove();
    history.replace('/listings');
  };

  const onDeleteClicked = () => {
    setConfirmDelete(true);
  };

  const onPublishClicked = async () => {
    setError(undefined);

    if (listingQuery.data?.primaryAgent == null) {
      setError('Cannot publish a listing without a primary contact!');
      return;
    }

    try {
      await publishListing({ listingId });
    } catch {
      setError('Error publishing the listing');
      return;
    }

    await listingQuery.refetch();
  };

  const onRefreshPosterClicked = async () => {
    try {
      await republishListingPosterMutation.mutateAsync();

      toast('Refreshed listing poster', {
        type: 'success',
        position: 'bottom-right',
      });
    } catch {
      toast('Failed to refresh listing poster', {
        type: 'error',
        position: 'bottom-right',
      });
    }
  };

  if (listingQuery.isLoading) {
    return <span>Loading...</span>;
  }

  if (listingQuery.isError) {
    return (
      <TextAlert
        label={
          (listingQuery.error as Error).message ||
          'Unexpected error occurred loading the listing'
        }
      />
    );
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            label:
              listingQuery.data != null
                ? listingQuery.data.property.propertyAddress
                : 'Listing Details',
          },
        ]}
      />

      {listingQuery.data && (
        <Heading title={listingQuery.data.property.propertyAddress}>
          <div className="flex align-middle">
            {listingQuery.data.listingStatus !== ListingStatus.Closed && (
              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onDeleteClicked}
              >
                Delete
              </button>
            )}
            {listingQuery.data.listingStatus === ListingStatus.Draft && (
              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onPublishClicked}
              >
                Publish
              </button>
            )}
            {listingQuery.data.listingStatus === ListingStatus.Active && (
              <button
                type="button"
                className="p-3 hover:bg-white hover:text-blue-500"
                onClick={() => setShowQrCode((x) => !x)}
                title={
                  showQrCode ? 'Hide listing QR code' : 'Show listing QR code'
                }
              >
                <QrcodeIcon height={32} />
              </button>
            )}
          </div>
        </Heading>
      )}

      {showQrCode && (
        <div className="bg-white border p-4 my-4 ">
          <div className="flex px-7 border items-center">
            <h3 className="flex-1 text-lg my-3">Listing QR Code</h3>

            <button
              type="button"
              title="Recreates the stored listing QR poster"
              className="hover:bg-blue-500 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed p-3"
              onClick={onRefreshPosterClicked}
              disabled={republishListingPosterMutation.isLoading}
            >
              Refresh Poster
            </button>
          </div>

          <div className="flex flex-col items-center p-3">
            <div className="flex justify-center">
              <QRCode
                value={JSON.stringify({
                  type: 'open-home',
                  id: listingQuery.data?.listingId,
                })}
              />
            </div>

            <a
              className="block bg-gray-700 text-white p-2 m-5 text-center"
              style={{ width: 256 }}
              href={`https://docs.squaremeter.co.nz/${listingQuery.data?.listingId}/listing-poster.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              View Poster
            </a>
          </div>
        </div>
      )}

      {error && (
        <div className="flex text-red-500 bg-red-200 border-red-400 border p-4">
          <div className="flex-1">{error}</div>
          <button
            type="button"
            title="Clear error"
            className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
            onClick={() => setError(undefined)}
          >
            <XIcon height={24} />
          </button>
        </div>
      )}

      {listingQuery.data && (
        <div className="bg-white border p-4">
          {/* listing overview */}
          {listingQuery.data.listingStatus !== ListingStatus.Draft && (
            <div className="m-5 mb-10">
              <div className="flex px-7 border items-center">
                <h3 className="text-lg my-3">Overview</h3>
              </div>

              <div className="flex flex-wrap p-3">
                <div className="m-3 p-3 border text-center w-52">
                  <h4 className="text-sm font-medium text-gray-500 my-3">
                    Age
                  </h4>
                  <div className="text-2xl font-bold text-gray-700 my-3">
                    {listingAge}
                  </div>
                </div>

                <div className="m-3 p-3 border text-center w-52">
                  <h4 className="text-sm font-medium text-gray-500 my-3">
                    Visitors
                  </h4>
                  <div className="text-2xl font-bold text-gray-700 my-3">
                    {listingQuery.data.visitors?.length}
                  </div>
                  <button
                    type="button"
                    className="block bg-gray-700 text-white w-full p-2"
                    onClick={() =>
                      history.push(`/listings/${listingId}/visitors`)
                    }
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* listing details */}
          <div className="m-5 mb-10">
            <div className="flex px-7 border items-center">
              <h3 className="flex-1 text-lg my-3">Listing Details</h3>

              <button
                type="button"
                className="hover:bg-blue-500 hover:text-white p-3"
                onClick={() =>
                  history.push(`/listings/${listingId}/edit-listing-details`)
                }
              >
                Edit
              </button>
            </div>

            <div className="p-3 w-full max-w-lg">
              <DescriptionList
                items={[
                  {
                    label: 'Listing Title',
                    value: `${listingQuery.data.listingTitle || 'None'}`,
                  },
                  {
                    label: 'Listing Type',
                    value: `${listingQuery.data.listingType}`,
                  },
                  {
                    label: 'Listing Status',
                    value: `${listingQuery.data.listingStatus}`,
                  },
                ]}
              />
            </div>
          </div>

          {/* property details */}
          <div className="m-5 mb-10">
            <div className="flex px-7 border items-center">
              <h3 className="flex-1 text-lg my-3">Property Details</h3>

              <button
                type="button"
                className="hover:bg-blue-500 hover:text-white p-3"
                onClick={() =>
                  history.push(`/listings/${listingId}/edit-property-details`)
                }
              >
                Edit
              </button>
            </div>

            <div className="p-3 w-full max-w-lg">
              <DescriptionList
                items={[
                  {
                    label: 'Bedrooms',
                    value: `${
                      listingQuery.data.property.bedrooms || 'unknown'
                    }`,
                  },
                  {
                    label: 'Bathrooms',
                    value: `${
                      listingQuery.data.property.bathrooms || 'unknown'
                    }`,
                  },
                  {
                    label: 'Floor area',
                    value: `${
                      listingQuery.data.property.floorArea || 'unknown'
                    }`,
                  },
                  {
                    label: 'Land area',
                    value: `${
                      listingQuery.data.property.landArea || 'unknown'
                    }`,
                  },
                ]}
              />
            </div>
          </div>

          {/* contact details */}
          <div className="m-5 mb-10">
            <div className="flex px-7 border items-center">
              <h3 className="flex-1 text-lg my-3">Contact Details</h3>

              {(session.accountDetails?.accountType === AccountType.Agent ||
                session.accountDetails?.accountType ===
                  AccountType.BranchManager ||
                session.accountDetails?.accountType ===
                  AccountType.Salesperson) && (
                <button
                  type="button"
                  className="hover:bg-blue-500 hover:text-white p-3"
                  onClick={() =>
                    history.push(`/listings/${listingId}/edit-contacts`)
                  }
                >
                  Edit
                </button>
              )}
            </div>

            <div>
              <div className="flex flex-wrap flex-col lg:flex-row">
                <div className="flex-1 p-3 max-w-lg">
                  <div className="p-3 text-center border">Primary Contact</div>
                  <div className="border border-t-0">
                    {listingQuery.data.primaryAgent && (
                      <DescriptionList
                        items={[
                          {
                            label: 'name',
                            value: `${listingQuery.data.primaryAgent.firstName} ${listingQuery.data.primaryAgent.lastName}`,
                          },
                          {
                            label: 'mobile',
                            value: listingQuery.data.primaryAgent.mobile,
                          },
                          {
                            label: 'phone',
                            value:
                              listingQuery.data.primaryAgent.phoneNumber || '',
                          },
                          {
                            label: 'email',
                            value: listingQuery.data.primaryAgent.email,
                          },
                        ]}
                      />
                    )}
                    {listingQuery.data.primaryAgent == null && (
                      <TextAlert label="No primary contact" />
                    )}
                  </div>
                </div>

                <div className="flex-1 p-3 max-w-lg">
                  <div className="p-3 text-center border">
                    Secondary Contact
                  </div>
                  <div className="border border-t-0">
                    {listingQuery.data.secondaryAgent && (
                      <DescriptionList
                        items={[
                          {
                            label: 'name',
                            value: `${listingQuery.data.secondaryAgent.firstName} ${listingQuery.data.secondaryAgent.lastName}`,
                          },
                          {
                            label: 'mobile',
                            value: listingQuery.data.secondaryAgent.mobile,
                          },
                          {
                            label: 'phone',
                            value:
                              listingQuery.data.secondaryAgent.phoneNumber ||
                              '',
                          },
                          {
                            label: 'email',
                            value: listingQuery.data.secondaryAgent.email,
                          },
                        ]}
                      />
                    )}
                    {listingQuery.data.secondaryAgent == null && (
                      <TextAlert label="No secondary contact" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* photos */}
          <div className="m-5 mb-10">
            <div className="flex px-7 border items-center">
              <h3 className="flex-1 text-lg my-3">Photos</h3>

              <button
                type="button"
                className="hover:bg-blue-500 hover:text-white p-3"
                onClick={() =>
                  history.push(`/listings/${listingId}/edit-photos`)
                }
              >
                Edit
              </button>
            </div>

            <div className="flex flex-wrap">
              {listingQuery.data.photos.map((photo, photoIndex) => (
                <div
                  key={photo.photoId}
                  className="m-3 border"
                  style={{ minWidth: 300 }}
                >
                  <img
                    className="h-52"
                    src={photo.photoUrl}
                    alt={`Property ${photoIndex}`}
                  />
                </div>
              ))}
            </div>
            <div className="m-3">
              {listingQuery.data.photos.length === 0 && (
                <TextAlert label="No photos" />
              )}
            </div>
          </div>

          {/* documents */}
          <div className="m-5 mb-10">
            <div className="flex px-7 border items-center">
              <h3 className="flex-1 text-lg my-3">Documents</h3>

              <button
                type="button"
                className="hover:bg-blue-500 hover:text-white p-3"
                onClick={() =>
                  history.push(`/listings/${listingId}/edit-documents`)
                }
              >
                Edit
              </button>
            </div>

            <div className="m-3">
              {listingQuery.data.documents.map((document) => (
                <div key={document.documentId}>
                  <a
                    href={document.documentUrl}
                    className="block text-gray-900 hover:bg-blue-500 hover:text-white rounded-md my-2 text-sm hover:no-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex items-center h-14 border">
                      <div className="h-full w-10 mr-2 flex justify-center items-center">
                        <DocumentIcon height={24} className="inline-block" />
                      </div>
                      <div className="flex-1 overflow-x-hidden whitespace-nowrap overflow-ellipsis">
                        {document.documentName}
                      </div>
                      <div className="flex h-full">
                        <div className="flex justify-center items-center px-2">
                          {documentSizes.get(document.documentId)}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {listingQuery.data.documents.length === 0 && (
                <TextAlert label="No documents" />
              )}
            </div>
          </div>
        </div>
      )}
      {!listingQuery.data && <TextAlert label="Listing cannot be found" />}

      <DialogConfirm
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        title="Confirm"
        onConfirm={() => onConfirmDelete()}
      >
        Are you sure you want to delete the listing?
      </DialogConfirm>
    </>
  );
};
