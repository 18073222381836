import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type ProvideMobileRequest = {
  mobile: string;
};

export async function provideMobile(
  request: ProvideMobileRequest
): Promise<void> {
  try {
    await apiClient.post<void>('account/confirm-mobile/send', {
      mobile: request.mobile,
    });
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        throw new ValidationError(
          'Mobile update failed',
          axiosError.response.data.errors
        );
      }
    }
    throw err;
  }
}
