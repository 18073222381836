import { apiClient } from './api-client';

export type Agent = {
  id: string;
  firstName: string;
  lastName: string;
  mobile: string;
  phoneNumber: string | undefined;
  email: string;
};

export type GetAgentsResponse = Agent[];

export async function getAgents(
  searchText: string
): Promise<GetAgentsResponse> {
  const q = encodeURIComponent(searchText);
  const result = await apiClient.get<GetAgentsResponse>(`agent/search?q=${q}`);

  return result.data;
}
