import { HomeIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

export type BreadcrumbItem = {
  route?: string;
  label: string;
};

type BreadcrumbProps = {
  item: BreadcrumbItem;
  isFirst: boolean;
  isLast: boolean;
};

export const Breadcrumb: React.VFC<BreadcrumbProps> = ({
  item,
  isFirst,
  isLast,
}: BreadcrumbProps) => {
  return (
    <>
      {isFirst && (
        <li className="flex items-center">
          <HomeIcon height={16} className="inline-block" />
          <span className="mx-2">/</span>
        </li>
      )}
      {item.route != null && (
        <li className="flex items-center">
          <Link to={item.route} className="text-blue-500 underline font-bold">
            {item.label}
          </Link>
        </li>
      )}
      {item.route == null && (
        <li className="flex items-center">{item.label}</li>
      )}
      {!isLast && (
        <li className="flex items-center">
          <span className="mx-2">/</span>
        </li>
      )}
    </>
  );
};

export type BreadcrumbsProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumbs: React.VFC<BreadcrumbsProps> = ({
  items,
}: BreadcrumbsProps) => {
  return (
    <nav className="bg-white border p-2 my-1 rounded w-full">
      <ol className="list-reset flex text-grey-dark text-sm">
        {items.map((item, itemIndex) => (
          <Breadcrumb
            // eslint-disable-next-line react/no-array-index-key
            key={itemIndex}
            item={item}
            isFirst={itemIndex === 0}
            isLast={itemIndex === items.length - 1}
          />
        ))}
      </ol>
    </nav>
  );
};
