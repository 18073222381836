import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ChangeListingDocumentsPositionsRequest } from './api-types';
import { ValidationError } from './common';

export async function changeListingDocumentPositions(
  listingId: string,
  request: ChangeListingDocumentsPositionsRequest
): Promise<void> {
  try {
    await apiClient.post<void>(`listing/${listingId}/documents/positions`, {
      listingDocumentsPositions: request.listingDocumentsPositions,
    });
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
