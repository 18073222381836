import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SessionContextProvider } from './contexts/SessionContext';
import { AppRoutes } from './AppRoutes';
import { AppLayout } from './components/AppLayout';
import { RouteChangeTracker } from './AppRouteChangeTracker';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

class DebugRouterX extends BrowserRouter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line no-console
    console.log(
      'initial history is: ',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      JSON.stringify((this as any).history, null, 2)
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).history.listen((location: any, action: any) => {
      // eslint-disable-next-line no-console
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      // eslint-disable-next-line no-console
      console.log(
        `The last navigation action was ${action}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        JSON.stringify((this as any).history, null, 2)
      );
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DebugRouter = DebugRouterX as any;

const App: React.VFC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionContextProvider>
        <DebugRouter>
          <RouteChangeTracker />

          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </DebugRouter>
      </SessionContextProvider>
    </QueryClientProvider>
  );
};

export default App;
