/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ValidationProblemDetails = ProblemDetails & { errors?: Record<string, string[]> };

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

/**
 * Request to confirm that the user that registered actually owns the email address provided during registration.
 */
export interface ConfirmEmailRequest {
  /** The 6 digit OTP (one-time password) email confirmation code. */
  code: string;
}

/**
 * Request to resend the email confirmation email, but also allowing the user to change their email address.
 */
export interface ResendEmailConfirmationEmailRequest {
  /**
   * The email address to send the email confirmation email to; if the email is different
   * to what was used during registration then the email will be updated -
   * so long as the email address is available.
   * @format email
   */
  email: string;
}

/**
 * Request to send the mobile confirmation code, but also allowing the user to change their mobile number.
 */
export interface SendMobileConfirmationCodeRequest {
  /**
   * The mobile number of the user.
   * @format phone
   */
  mobile: string;
}

/**
 * Request to confirm the OTP (one-time password) code sent to the mobile of the user.
 */
export interface ConfirmMobileRequest {
  /** The 6 digit OTP (one-time password) mobile confirmation code. */
  code: string;
}

/**
 * The result from querying for the account details of a user.
 */
export interface AccountQueryResult {
  /** The user identifier associated with the session. */
  userId: string;

  /** The URL for the avatar of the user. */
  avatarUrl?: string | null;

  /** The personal details of the user. */
  userDetails: UserDetails;

  /** The type of account the user is subscribed to. */
  accountType: AccountType;

  /** If the user is a professional, then the user will have agent details. */
  agentDetails?: AgentDetails | null;
}

/**
 * The details of the user.
 */
export interface UserDetails {
  /** The first name of the user. */
  firstName: string;

  /** The last name of the user. */
  lastName: string;

  /** The email of the user. */
  email: string;

  /** Whether the email of the user is verified. */
  emailVerified: boolean;

  /** The mobile of the user. */
  mobile: string;

  /** Whether the mobile of the user is verified. */
  mobileVerified: boolean;

  /** The physical address of the user. */
  address?: string | null;
}

/**
 * The type of user account.
 */
export enum AccountType {
  User = "User",
  PrivateSeller = "PrivateSeller",
  Salesperson = "Salesperson",
  BranchManager = "BranchManager",
  Agent = "Agent",
}

/**
 * The details of a professional real estate agent.
 */
export interface AgentDetails {
  /** Agent can optionally work for an agency. */
  agencyDetails: AgencyDetails;
}

/**
 * The details of a real estate agency.
 */
export interface AgencyDetails {
  /** The name of the business. */
  name: string;
}

/**
 * The HTTP response body when changing account details has succeeded.
 */
export interface ChangeAccountDetailsSuccessResult {
  /** Determines whether the email associated with the Google account is verified or not. */
  mobileVerified: boolean;
}

/**
 * Request for updating the user account details.
 */
export interface ChangeAccountDetailsRequest {
  /** The first name of the user. */
  firstName: string;

  /** The last name of the user. */
  lastName: string;

  /**
   * The mobile number of the user.
   * @format phone
   */
  mobile: string;

  /** The optional address of the user. */
  address?: string | null;
}

export interface IHeaderDictionary {
  Item?: any[];

  /** @format int64 */
  ContentLength?: number | null;
}

/**
 * The physical address of a property.
 */
export interface PropertyAddress {
  /** The identifier of the physical property address. */
  id: string;

  /** The property's full address label. */
  label: string;
}

/**
 * The HTTP response body when account login has succeeded.
 */
export interface LoginAccountSuccessResult {
  /** The access token issued when authentication succeeds, which must be used with all authenticated endpoints. */
  accessToken: string;
}

/**
 * Request object for authenticating using the username and password authentication mechanism, as opposed to a social login provider.
 */
export interface LoginRequest {
  /** The username of the registered user. */
  username: string;

  /** The password of the registered user. */
  password: string;
}

/**
 * The HTTP response body when account registration has succeeded.
 */
export interface RegisterAccountSuccessResult {
  /** The access token issued when authentication succeeds after registration, which must be used with all authenticated endpoints. */
  accessToken: string;
}

/**
 * Request object for registering a user using the username and password authentication mechanism, as opposed to a social login provider.
 */
export interface RegisterRequest {
  /**
   * The email address of the user.
   * This email address cannot match an existing users email address.
   * This email will be verified before the user is considered fully registered.
   * @format email
   */
  email: string;

  /** The password of the user. */
  password: string;

  /** The first name of the user. */
  firstName: string;

  /** The last name of the user. */
  lastName: string;

  /** The optional address of the user. */
  address?: string | null;
}

/**
 * Request for sending a password reset email to the user, so they can set a new password.
 */
export interface ResetPasswordRequest {
  /**
   * The email to send the reset password code to.
   * @format email
   */
  email: string;
}

/**
 * Request for updating the user password using the password reset code received via email.
 */
export interface ChangePasswordRequest {
  /** The password reset code that was provided in the password reset email. */
  resetCode: string;

  /** The new password the user has chosen. */
  newPassword: string;
}

/**
 * The HTTP response body when creating a listing has succeeded.
 */
export interface CreateListingSuccessResult {
  /** The listing identifier. */
  listingId?: string | null;
}

/**
 * Request to create a new listing.
 */
export interface CreateListingRequest {
  /** The identifier of physical address of the property. */
  propertyAddressId?: string | null;

  /** The address of the property that could not be found. */
  nonRegisteredPropertyAddress?: NonRegisteredPropertyAddress | null;

  /** The sales activity associated with the open home. */
  listingType: ListingType;

  /** The sales title of the listing. */
  listingTitle?: string | null;

  /**
   * The number of bedrooms of the property.
   * @format int32
   */
  bedrooms?: number | null;

  /**
   * The number of bathrooms of the property.
   * @format int32
   */
  bathrooms?: number | null;

  /**
   * The area in square meters inside the property.
   * @format int32
   */
  floorArea?: number | null;

  /**
   * The area in square meters of the land of the property.
   * @format int32
   */
  landArea?: number | null;
}

/**
 * The address of a new property.
 */
export interface NonRegisteredPropertyAddress {
  /** The street number of the property address. */
  streetNumber?: string | null;

  /** The street name of the property address. */
  streetName?: string | null;

  /** The suburb of the property address. */
  suburb?: string | null;

  /** The town or city of the property address. */
  townOrCity?: string | null;
}

/**
 * The sales type related to the open home.
 */
export enum ListingType {
  Sale = "Sale",
  Rent = "Rent",
}

export interface JsonDocument {
  rootElement?: JsonElement;
}

export interface JsonElement {
  valueKind?: JsonValueKind;
}

export enum JsonValueKind {
  Undefined = "Undefined",
  Object = "Object",
  Array = "Array",
  String = "String",
  Number = "Number",
  True = "True",
  False = "False",
  Null = "Null",
}

/**
 * The HTTP response body when uploading listing photos has succeeded.
 */
export interface UploadListingPhotosSuccessResult {
  /** The listing photos that were uploaded. */
  photos: ListingPhoto[];
}

/**
 * An active open home photo.
 */
export interface ListingPhoto {
  /** The identifier of the listing photo. */
  photoId: string;

  /** The name of the listing photo. */
  photoName: string;

  /**
   * The number of bytes of the listing photo.
   * @format int64
   */
  photoSize: number;

  /** The URL of the listing photo. */
  photoUrl: string;
}

/**
 * Request to change the positions of listing photos.
 */
export interface ChangeListingPhotosPositionsRequest {
  /**
   * The positions of listing photos.
   *
   */
  listingPhotosPositions: ListingPhotoPosition[];
}

/**
 * Position of a listing photo in the list of listing photos.
 */
export interface ListingPhotoPosition {
  /** The identifier of the listing photo. */
  photoId: string;

  /**
   * The new position of the listing photo.
   * @format int32
   */
  position: number;
}

/**
 * Request to change the positions of listing documents.
 */
export interface ChangeListingDocumentsPositionsRequest {
  /**
   * The positions of listing documents.
   *
   */
  listingDocumentsPositions: ListingDocumentPosition[];
}

/**
 * Position of a listing document in the list of listing documents.
 */
export interface ListingDocumentPosition {
  /** The identifier of the listing document. */
  documentId: string;

  /**
   * The new position of the listing document.
   * @format int32
   */
  position: number;
}

/**
 * Details of an open home.
 */
export interface ListingDetails {
  /** The identifier of the listing the open home belongs to. */
  listingId: string;

  /** The type of the listing, whether for rent or sale. */
  listingType: ListingType;

  /** The status of the listing. */
  listingStatus: ListingStatus;

  /**
   * When the listing was published.
   * @format date-time
   */
  publishedAt?: string | null;

  /**
   * When the listing was closed.
   * @format date-time
   */
  closedAt?: string | null;

  /** The sales title of the listing. */
  listingTitle?: string | null;

  /** The open home property. */
  property: ListingPropertyOverview;

  /** The primary agent who 'owns' the listing. */
  primaryAgent?: ListingAgentOverview | null;

  /** The secondary agent who can act on behalf of the primary agent. */
  secondaryAgent?: ListingAgentOverview | null;

  /** The visitors of the open home; which is returned only for the agent. */
  visitors?: OpenHomeVisitDetails[] | null;

  /** The photos of the listing. */
  photos: ListingPhoto[];

  /** The documents of the listing. */
  documents: ListingDocument[];

  /** The upcoming open homes for the listing. */
  upcomingOpenHomes: OpenHome[];
}

/**
 * The status of an open home.
 */
export enum ListingStatus {
  Draft = "Draft",
  Active = "Active",
  Closed = "Closed",
}

/**
 * The details of the property for the open home.
 */
export interface ListingPropertyOverview {
  /** The physical property address of the open home. */
  propertyAddress: string;

  /**
   * The number of bedrooms of the property.
   * @format int32
   */
  bedrooms?: number | null;

  /**
   * The number of bathrooms of the property.
   * @format int32
   */
  bathrooms?: number | null;

  /**
   * The area in square meters inside the property.
   * @format int32
   */
  floorArea?: number | null;

  /**
   * The area in square meters of the land of the property.
   * @format int32
   */
  landArea?: number | null;
}

/**
 * The overview of an agent that represents the property for the listing.
 */
export interface ListingAgentOverview {
  /** The user identifier of the agent. */
  userId: string;

  /** The URL for the avatar of the user. */
  avatarUrl?: string | null;

  /** The first name of the agent. */
  firstName: string;

  /** The last name of the agent. */
  lastName: string;

  /** The email of the agent. */
  email: string;

  /** The landline phone number of the agent. */
  phoneNumber?: string | null;

  /** The mobile phone number of the agent. */
  mobile: string;

  /** The name of the agency the agent works for. */
  agencyName?: string | null;

  /** The logo URL for the agency the agent works for. */
  agencyLogoUrl?: string | null;

  /** The logo background colour when displaying the agency logo. */
  agencyLogoBackgroundColour?: string | null;
}

/**
 * Person who visited the open home.
 */
export interface OpenHomeVisitDetails {
  /**
   * When the person visited the property.
   * @format date-time
   */
  visitedAt: string;

  /** The user identifier of the visitor who is registered as a user in the system. */
  userId?: string | null;

  /** The visitor identifier of the visitor who is not registered in the system. */
  visitorId?: string | null;

  /** The first name of the visitor. */
  firstName: string;

  /** The last name of the visitor. */
  lastName: string;

  /** The email address of the visitor. */
  email: string;

  /** The mobile phone number of the visitor. */
  mobile: string;

  /** Determines if the mobile phone number of the visitor is confirmed. */
  mobileConfirmed: boolean;

  /** The address of the visitor. */
  address: string;

  /** The URL for the avatar of the user. */
  avatarUrl?: string | null;

  /**
   * The number of total open home visits.
   * @format int32
   */
  numberOfVisits: number;
}

/**
 * An active open home document.
 */
export interface ListingDocument {
  /** The identifier of the listing document. */
  documentId: string;

  /** The name of the listing document. */
  documentName: string;

  /**
   * The number of bytes of the listing document.
   * @format int64
   */
  documentSize: number;

  /** The URL of the listing document. */
  documentUrl: string;
}

/**
 * The details of an open home for a listing.
 */
export interface OpenHome {
  /** The identifier of the open home. */
  openHomeId: string;

  /**
   * The date and time of the start of the open home.
   * @format date-time
   */
  startsAt: string;

  /**
   * The duration of the open home in minutes.
   * @format int32
   */
  durationInMinutes: number;

  /** The status of the open home. */
  status: OpenHomeStatus;
}

/**
 * The status of the open home.
 */
export enum OpenHomeStatus {
  Active = "Active",
  Canceled = "Canceled",
  Deleted = "Deleted",
}

/**
 * Overview details of a listing.
 */
export interface ListingOverview {
  /** The identifier of the listing the open home belongs to. */
  listingId: string;

  /** The type of the listing, whether for rent or sale. */
  listingType: ListingType;

  /** The status of the listing. */
  listingStatus: ListingStatus;

  /** The sales title of the listing. */
  listingTitle?: string | null;

  /** The listing property overview. */
  property: ListingPropertyOverview;

  /** The primary listing photo URL. */
  listingPhotoUrl?: string | null;

  /**
   * The next date an open home will be conducted.
   * @format date-time
   */
  nextOpenHomeDate?: string | null;

  /** The primary agent who 'owns' the listing. */
  primaryAgent?: ListingAgentOverview | null;

  /** The secondary agent who can act on behalf of the primary agent. */
  secondaryAgent?: ListingAgentOverview | null;
}

/**
 * Request to add an open home visitor who didn't scan a QR code.
 */
export interface AddListingOpenHomeVisitorForNonUserRequest {
  /** The first name of the open home visitor. */
  firstName: string;

  /** The last name of the open home visitor. */
  lastName: string;

  /** The email address of the open home visitor. */
  email?: string | null;

  /** The mobile of the open home visitor. */
  mobile?: string | null;

  /** The physical address of the open home visitor. */
  address?: string | null;
}

/**
 * The HTTP response body when adding a visitor to a listing succeeded.
 */
export interface AddOpenHomeVisitorSuccessResult {
  /** The visitor overview. */
  visitor?: VisitorOverview | null;

  /** The listing overview. */
  listing?: ListingOverview | null;
}

/**
 * The overview of a visitor to an open home.
 */
export interface VisitorOverview {
  /** The URL for the avatar of the visitor. */
  avatarUrl?: string | null;

  /** The first name of the visitor. */
  firstName?: string | null;

  /** The last name of the visitor. */
  lastName?: string | null;

  /** The email of the visitor. */
  email?: string | null;

  /** The mobile of the visitor. */
  mobile?: string | null;

  /** Whether the mobile of the visitor is confirmed or not. */
  mobileConfirmed?: boolean;

  /** The physical address of the visitor. */
  address?: string | null;
}

/**
 * The payment plan that an account holder can choose to upgrade an account.
 */
export interface PaymentPlan {
  /** The payment plan identifier. */
  planId?: string | null;

  /** The name of the payment plan. */
  planName?: string | null;

  /**
   * The price of the paln.
   * @format decimal
   */
  price?: number;

  /** The payment frequency of the plan. */
  frequency?: PaymentPlanFrequency;
}

/**
 * Determines how often payment is required for the plan.
 */
export enum PaymentPlanFrequency {
  Monthly = "Monthly",
  Annually = "Annually",
}
