import React from 'react';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Session } from '../../contexts/SessionContext';
import { ListingCreate } from './ListingCreate';
import { ListingDetails } from './ListingDetails';
import { ListingEditContacts } from './ListingEditContacts';
import { ListingEditDetails } from './ListingEditDetails';
import { ListingEditDocuments } from './ListingEditDocuments';
import { ListingEditPhotos } from './ListingEditPhotos';
import { ListingEditPropertyDetails } from './ListingEditPropertyDetails';
import { Listings } from './Listings';
import { ListingVisitors } from './ListingVisitors';

export const getSellerRoutes = (session: Session): React.ReactNode[] => [
  <ProtectedRoute
    session={session}
    exact
    path="/listings"
    component={Listings}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/create"
    component={ListingCreate}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId"
    component={ListingDetails}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/visitors"
    component={ListingVisitors}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/edit-listing-details"
    component={ListingEditDetails}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/edit-property-details"
    component={ListingEditPropertyDetails}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/edit-contacts"
    component={ListingEditContacts}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/edit-photos"
    component={ListingEditPhotos}
  />,
  <ProtectedRoute
    session={session}
    exact
    path="/listings/:listingId/edit-documents"
    component={ListingEditDocuments}
  />,
];
