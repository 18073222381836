import { useQuery } from 'react-query';
import { getVisitedListings } from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { TextAlert } from '../../../components/TextAlert';
import { ListingCard } from './ListingCard';

export const Layout: React.VFC = () => {
  const { isLoading, isError, error, data } = useQuery(
    'listings',
    getVisitedListings
  );

  if (isLoading || data == null) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return (
      <TextAlert
        label={
          (error as Error).message ||
          'Unexpected error occurred loading the listings'
        }
      />
    );
  }

  return (
    <div>
      <Breadcrumbs items={[{ label: 'Visited Properties' }]} />

      <Heading title="Visited Properties" />

      <div className="bg-white shadow-md rounded">
        {data.length === 0 && (
          <TextAlert label="You have not visited an open home yet" />
        )}
        {data.length > 0 && (
          <div className="p-px flex flex-wrap">
            {data.map((listing) => (
              <ListingCard key={listing.listingId} listing={listing} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
