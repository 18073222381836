import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type RemoveListingDocumentRequest = {
  listingId: string;
  listingDocumentId: string;
};

export async function removeListingDocument(
  request: RemoveListingDocumentRequest
): Promise<void> {
  try {
    await apiClient.delete(
      `listing/${request.listingId}/documents/${request.listingDocumentId}`
    );
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
