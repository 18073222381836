import { apiClient } from './api-client';
import { ListingDetails } from './api-types';

export async function getListingDetails(
  listingId: string
): Promise<ListingDetails> {
  const result = await apiClient.get<ListingDetails>(`listing/${listingId}`);

  return result.data;
}
