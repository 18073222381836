import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type UpdateListingDetailsRequestChanges = {
  listingTitle?: string | null;
  contact1?: string | null;
  contact2?: string | null;
};

export type UpdateListingDetailsRequest = {
  listingId: string;
  changes: UpdateListingDetailsRequestChanges;
};

export async function updateListingDetails(
  request: UpdateListingDetailsRequest
): Promise<void> {
  try {
    await apiClient.patch(`listing/${request.listingId}`, request.changes);
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
