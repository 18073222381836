/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { XIcon } from '@heroicons/react/outline';
import { useCallback, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import {
  ListingDocument,
  changeListingDocumentPositions,
  uploadListingDocuments,
  removeListingDocument,
  UploadListingDocumentsSuccessResult,
} from '../../../api';
import { FileDropZone } from '../../../components/FileDropZone';
import { Heading } from '../../../components/Heading';
import { Document } from './Document';

export type DocumentsEditorProps = {
  listingId: string;
  documents: ListingDocument[];
  changed: () => Promise<unknown>;
};

type DocumentAdded = {
  type: 'added';
  documentId: string;
  file: File;
};
type DocumentRemoved = {
  type: 'removed';
  documentId: string;
};
type DocumentMoved = {
  type: 'moved';
  documentId: string;
  position: number;
};
type DocumentChange = DocumentAdded | DocumentRemoved | DocumentMoved;

type MyDocumentExisting = ListingDocument & { isNew: false };
type MyDocumentNew = ListingDocument & {
  isNew: true;
  file: File;
};
type MyDocument = MyDocumentExisting | MyDocumentNew;

export const DocumentsEditor: React.VFC<DocumentsEditorProps> = ({
  listingId,
  documents,
  changed,
}: DocumentsEditorProps) => {
  const [myDocuments, setMyDocuments] = useState<MyDocument[]>(
    documents.map((document) => ({ ...document, isNew: false }))
  );
  const changes = useMemo<DocumentChange[]>(() => {
    const addedDocuments: DocumentAdded[] = (
      myDocuments.filter((myDocument) => myDocument.isNew) as MyDocumentNew[]
    ).map((myDocument) => ({
      type: 'added',
      documentId: myDocument.documentId,
      file: myDocument.file,
    }));

    const removedDocuments: DocumentRemoved[] = documents
      .filter(
        (document) =>
          myDocuments.find(
            (myDocument) => myDocument.documentId === document.documentId
          ) == null
      )
      .map((myDocument) => ({
        type: 'removed',
        documentId: myDocument.documentId,
      }));

    const movedDocuments: DocumentMoved[] = myDocuments
      .filter(
        (myDocument, myDocumentIndex) =>
          documents.findIndex(
            (document) => document.documentId === myDocument.documentId
          ) !== myDocumentIndex
      )
      .map((myDocument) => ({
        type: 'moved',
        documentId: myDocument.documentId,
        position: myDocuments.indexOf(myDocument) + 1,
      }));

    return [...addedDocuments, ...removedDocuments, ...movedDocuments];
  }, [documents, myDocuments]);
  const [error, setError] = useState<string>();

  const onDocumentsChosen = async (files: File[]) => {
    setMyDocuments((currentDocuments) => [
      ...currentDocuments,
      ...files.map((file) => ({
        documentId: `new-${uuidv4()}`,
        documentName: file.name,
        documentSize: file.size,
        documentUrl: URL.createObjectURL(file),
        isNew: true,
        file,
      })),
    ]);
  };

  const onRemoveDocumentClick = async (listingDocumentId: string) => {
    setMyDocuments((currentDocuments) => [
      ...currentDocuments.filter(
        (document) => document.documentId !== listingDocumentId
      ),
    ]);
  };

  const onCancelClicked = () => {
    setError(undefined);
    setMyDocuments(
      documents.map((document) => ({ ...document, isNew: false }))
    );
  };

  const onSaveClicked = async () => {
    setError(undefined);

    // 1. upload documents
    const changesToAdd = changes.filter(
      (change) => change.type === 'added'
    ) as DocumentAdded[];

    const filesToAdd = changesToAdd.map((x) => x.file);
    const fileAliases = new Map<string, string>([]);

    if (filesToAdd.length) {
      let uploadResult: UploadListingDocumentsSuccessResult | undefined;

      try {
        uploadResult = await uploadListingDocuments({
          listingId,
          files: filesToAdd,
        });
      } catch (err) {
        setError(
          'Error uploading the new documents. Ensure that each file is under 20MB.'
        );
        return;
      }

      const addedDocuments = uploadResult!.documents;

      // update documentId for each added document
      changesToAdd.forEach((changeToAdd, changeToAddIndex) => {
        setMyDocuments((myDocumentsToUpdate) => {
          const documentIndex = myDocumentsToUpdate.findIndex(
            (myDocument) => myDocument.documentId === changeToAdd.documentId
          )!;
          const updatedDocument: MyDocumentExisting = {
            documentId: addedDocuments[changeToAddIndex].documentId,
            documentName: addedDocuments[changeToAddIndex].documentName,
            documentSize: addedDocuments[changeToAddIndex].documentSize,
            documentUrl: addedDocuments[changeToAddIndex].documentUrl,
            isNew: false,
          };
          fileAliases.set(
            changeToAdd.documentId,
            addedDocuments[changeToAddIndex].documentId
          );

          const updatedMyDocumentsToUpdate = [...myDocumentsToUpdate];
          updatedMyDocumentsToUpdate[documentIndex] = updatedDocument;

          return updatedMyDocumentsToUpdate;
        });
      });

      await changed();
    }

    // 2. remove documents
    const documentIdsToRemove = (
      changes.filter((change) => change.type === 'removed') as DocumentRemoved[]
    ).map((changeToRemove) => changeToRemove.documentId);

    if (documentIdsToRemove.length) {
      await Promise.all(
        documentIdsToRemove.map((documentIdToRemove) =>
          removeListingDocument({
            listingId,
            listingDocumentId: documentIdToRemove,
          })
        )
      );

      await changed();
    }

    // 3. move documents
    const listingDocumentsPositions = (
      changes.filter((change) => change.type === 'moved') as DocumentMoved[]
    ).map((movedDocument) => ({
      documentId: fileAliases.has(movedDocument.documentId)
        ? fileAliases.get(movedDocument.documentId)!
        : movedDocument.documentId,
      position: movedDocument.position,
    }));

    if (listingDocumentsPositions.length) {
      await changeListingDocumentPositions(listingId, {
        listingDocumentsPositions,
      });

      await changed();
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex == null || hoverIndex == null) return;

      const dragCard = myDocuments[dragIndex];

      const newDocuments = [...myDocuments];
      newDocuments.splice(dragIndex, 1);
      newDocuments.splice(hoverIndex, 0, dragCard);
      setMyDocuments(newDocuments);
    },
    [myDocuments]
  );

  return (
    <div className="bg-white">
      <Heading title="Change Listing Documents">
        {changes.length !== 0 && (
          <>
            <button
              type="button"
              className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
              onClick={onCancelClicked}
            >
              Reset
            </button>

            <button
              type="button"
              className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
              onClick={onSaveClicked}
            >
              Save
            </button>
          </>
        )}
      </Heading>

      {error && (
        <div className="flex text-red-500 bg-red-200 m-3 border-red-400 border p-4">
          <div className="flex-1">{error}</div>
          <button
            type="button"
            title="Clear error"
            className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
            onClick={() => setError(undefined)}
          >
            <XIcon height={24} />
          </button>
        </div>
      )}

      <DndProvider backend={HTML5Backend}>
        <div>
          {myDocuments.map((document, documentIndex) => (
            <Document
              key={document.documentId}
              documentIndex={documentIndex}
              documentId={document.documentId}
              documentName={document.documentName}
              documentSize={document.documentSize}
              documentUrl={document.documentUrl}
              removeClick={() => onRemoveDocumentClick(document.documentId)}
              moveCard={moveCard}
            />
          ))}
        </div>
      </DndProvider>

      <div className="p-8 flex justify-center">
        <div className="h-72 w-full max-w-4xl">
          <FileDropZone
            label="documents"
            fileTypes={[{ label: 'PDF', mimeType: 'application/pdf' }]}
            filesAdded={onDocumentsChosen}
          />
        </div>
      </div>
      {/* <h2>Changes</h2>
      <pre>{JSON.stringify(changes, undefined, 2)}</pre>
      <h2>Documents</h2>
      <pre>{JSON.stringify(documents, undefined, 2)}</pre>
      <h2>My Documents</h2>
      <pre>{JSON.stringify(myDocuments, undefined, 2)}</pre> */}
    </div>
  );
};
