import React, { useEffect, useMemo } from 'react';
import { ListingDetails } from '../../../api';

export type FormData = {
  bedrooms: number | null;
  bathrooms: number | null;
  floorArea: number | null;
  landArea: number | null;
};

export type FormProps = {
  listing: ListingDetails;
  formData: FormData;
  onFormDataChange: (value: FormData) => void;
  onFormChangesChange: (value: Map<keyof FormData, number | null>) => void;
};

export const Form: React.VFC<FormProps> = ({
  listing,
  formData,
  onFormDataChange,
  onFormChangesChange,
}: FormProps) => {
  const changes = useMemo(() => {
    const changesMap = new Map<keyof FormData, number | null>();
    if (listing.property.bedrooms !== formData.bedrooms) {
      changesMap.set('bedrooms', formData.bedrooms);
    }
    if (listing.property.bathrooms !== formData.bathrooms) {
      changesMap.set('bathrooms', formData.bathrooms);
    }
    if (listing.property.floorArea !== formData.floorArea) {
      changesMap.set('floorArea', formData.floorArea);
    }
    if (listing.property.landArea !== formData.landArea) {
      changesMap.set('landArea', formData.landArea);
    }
    return changesMap;
  }, [listing, formData]);

  useEffect(() => {
    const newFormData: FormData = {
      bedrooms: listing.property.bedrooms || null,
      bathrooms: listing.property.bathrooms || null,
      floorArea: listing.property.floorArea || null,
      landArea: listing.property.landArea || null,
    };

    onFormDataChange(newFormData);
  }, [listing, onFormDataChange]);

  useEffect(() => {
    onFormChangesChange(changes);
  }, [changes, onFormChangesChange]);

  const onlistingPropertyDetailsInput = (
    event: React.FormEvent<HTMLInputElement>,
    field: keyof FormData
  ) => {
    const { value } = event.target as HTMLInputElement;
    let newValue = value === '' ? null : parseInt(value, 10);
    newValue = Number.isNaN(newValue) ? null : newValue;

    const newFormData: FormData = {
      ...formData,
    };
    newFormData[field] = newValue;

    onFormDataChange(newFormData);
  };

  return (
    <>
      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="bedrooms">Bedrooms</label>
        <input
          name="bedrooms"
          type="text"
          className="block p-2 border w-full"
          value={formData.bedrooms == null ? '' : formData.bedrooms}
          onInput={(event) => onlistingPropertyDetailsInput(event, 'bedrooms')}
        />
      </div>

      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="bathrooms">Bathrooms</label>
        <input
          name="bathrooms"
          type="text"
          className="block p-2 border w-full"
          value={formData.bathrooms == null ? '' : formData.bathrooms}
          onInput={(event) => onlistingPropertyDetailsInput(event, 'bathrooms')}
        />
      </div>

      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="floorArea">Floor Area</label>
        <input
          name="floorArea"
          type="text"
          className="block p-2 border w-full"
          value={formData.floorArea == null ? '' : formData.floorArea}
          onInput={(event) => onlistingPropertyDetailsInput(event, 'floorArea')}
        />
      </div>

      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="landArea">Land Area</label>
        <input
          name="landArea"
          type="text"
          className="block p-2 border w-full"
          value={formData.landArea == null ? '' : formData.landArea}
          onInput={(event) => onlistingPropertyDetailsInput(event, 'landArea')}
        />
      </div>

      {/* <div>
        changes:{' '}
        {JSON.stringify(Object.fromEntries(changes.entries()), undefined, 2)}
      </div> */}
    </>
  );
};
