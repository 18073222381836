import { FormEvent, useMemo, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { getAccountDetails, register, ValidationError } from '../../api';
import { useSessionContext } from '../../contexts/SessionContext';

export const Register: React.VFC = () => {
  const [session, setSession] = useSessionContext();
  const [error, setError] = useState<string>();
  const [fieldErrors, setFieldErrors] = useState<
    | {
        [key: string]: string[];
      }
    | undefined
  >();
  const emailError = useMemo(
    () =>
      fieldErrors && fieldErrors.Email != null
        ? fieldErrors.Email[0]
        : undefined,
    [fieldErrors]
  );
  const passwordError = useMemo(
    () =>
      fieldErrors && fieldErrors.Password != null
        ? fieldErrors.Password[0]
        : undefined,
    [fieldErrors]
  );
  const firstNameError = useMemo(
    () =>
      fieldErrors && fieldErrors.FirstName != null
        ? fieldErrors.FirstName[0]
        : undefined,
    [fieldErrors]
  );
  const lastNameError = useMemo(
    () =>
      fieldErrors && fieldErrors.LastName != null
        ? fieldErrors.LastName[0]
        : undefined,
    [fieldErrors]
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setError(undefined);
    setFieldErrors(undefined);

    ReactGA.event({
      category: 'Auth',
      action: 'Register Clicked',
    });

    const email = (
      (event.target as HTMLFormElement).elements.namedItem(
        'email'
      ) as HTMLInputElement
    ).value;
    const password = (
      (event.target as HTMLFormElement).elements.namedItem(
        'password'
      ) as HTMLInputElement
    ).value;
    const firstName = (
      (event.target as HTMLFormElement).elements.namedItem(
        'firstName'
      ) as HTMLInputElement
    ).value;
    const lastName = (
      (event.target as HTMLFormElement).elements.namedItem(
        'lastName'
      ) as HTMLInputElement
    ).value;

    try {
      const registerResult = await register(
        email,
        password,
        firstName,
        lastName
      );

      localStorage.setItem('access_token', registerResult.accessToken);

      const accountDetails = await getAccountDetails();
      localStorage.setItem('account_details', JSON.stringify(accountDetails));

      setSession((s) => ({
        ...s,
        isAuthenticated: true,
        accountDetails,
      }));

      ReactGA.set({
        userId: accountDetails.userId,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        setFieldErrors(err.errors);
      } else if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (session.isAuthenticated && session.accountDetails != null) {
    if (session.accountDetails.userDetails.emailVerified === false) {
      return <Redirect to={{ pathname: '/verify-email' }} />;
    }
    if (session.accountDetails.userDetails.mobile == null) {
      return <Redirect to={{ pathname: '/provide-mobile' }} />;
    }
    if (session.accountDetails.userDetails.mobileVerified === false) {
      return <Redirect to={{ pathname: '/verify-mobile' }} />;
    }

    return <Redirect to={session.redirectPath} />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-72 pt-20">
        <form onSubmit={onSubmit}>
          <label htmlFor="email" className="block m-2">
            Email
            <input
              id="email"
              name="email"
              type="email"
              className="p-1 border block w-full"
              disabled={loading}
            />
            {emailError && <div className="text-red-400">{emailError}</div>}
          </label>
          <label htmlFor="password" className="block m-2">
            Password
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              className="p-1 border block w-full"
              disabled={loading}
            />
            {passwordError && (
              <div className="text-red-400">{passwordError}</div>
            )}
          </label>
          <label htmlFor="firstName" className="block m-2">
            First name
            <input
              id="firstName"
              name="firstName"
              className="p-1 border block w-full"
              disabled={loading}
            />
            {firstNameError && (
              <div className="text-red-400">{firstNameError}</div>
            )}
          </label>
          <label htmlFor="lastName" className="block m-2">
            Last name
            <input
              id="lastName"
              name="lastName"
              className="p-1 border block w-full"
              disabled={loading}
            />
            {lastNameError && (
              <div className="text-red-400">{lastNameError}</div>
            )}
          </label>
          {error && (
            <div className="p-2 m-2 bg-red-400 text-white">{error}</div>
          )}
          <div className="m-2">
            <button
              type="submit"
              className="block bg-gray-700 text-white w-full p-2"
              disabled={loading}
            >
              Register
            </button>
          </div>

          <div>
            <div className="flex items-center">
              <div className="border-b flex-1" />
              <div className="p-2 text-gray-500 -mt-1">or</div>
              <div className="border-b flex-1" />
            </div>

            <div className="text-gray-500 text-center">
              Do you have an account?{' '}
              <Link
                to="/login"
                className="text-gray-900 hover:text-blue-500 underline"
              >
                Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
