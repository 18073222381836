import { ExclamationIcon } from '@heroicons/react/outline';

export type TextAlertProps = {
  label: string;
};

export const TextAlert: React.VFC<TextAlertProps> = ({
  label,
}: TextAlertProps) => {
  return (
    <div className="p-4 flex items-center text-yellow-700">
      <ExclamationIcon className="-mb-1" height={24} />
      <div className="ml-2">{label}</div>
    </div>
  );
};
