/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { XIcon } from '@heroicons/react/outline';
import { useCallback, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import {
  ListingPhoto,
  changeListingPhotosPositions,
  removeListingPhoto,
  uploadListingPhotos,
  UploadListingPhotosSuccessResult,
} from '../../../api';
import { FileDropZone } from '../../../components/FileDropZone';
import { Heading } from '../../../components/Heading';
import { Photo } from './Photo';

export type PhotosEditorProps = {
  listingId: string;
  photos: ListingPhoto[];
  changed: () => Promise<unknown>;
};

type PhotoAdded = {
  type: 'added';
  photoId: string;
  file: File;
};
type PhotoRemoved = {
  type: 'removed';
  photoId: string;
};
type PhotoMoved = {
  type: 'moved';
  photoId: string;
  position: number;
};
type PhotoChange = PhotoAdded | PhotoRemoved | PhotoMoved;

type MyPhotoExisting = ListingPhoto & { isNew: false };
type MyPhotoNew = ListingPhoto & {
  isNew: true;
  file: File;
};
type MyPhoto = MyPhotoExisting | MyPhotoNew;

export const PhotosEditor: React.VFC<PhotosEditorProps> = ({
  listingId,
  photos,
  changed,
}: PhotosEditorProps) => {
  const [myPhotos, setMyPhotos] = useState<MyPhoto[]>(
    photos.map((photo) => ({ ...photo, isNew: false }))
  );
  const changes = useMemo<PhotoChange[]>(() => {
    const addedPhotos: PhotoAdded[] = (
      myPhotos.filter((myPhoto) => myPhoto.isNew) as MyPhotoNew[]
    ).map((myPhoto) => ({
      type: 'added',
      photoId: myPhoto.photoId,
      file: myPhoto.file,
    }));

    const removedPhotos: PhotoRemoved[] = photos
      .filter(
        (photo) =>
          myPhotos.find((myPhoto) => myPhoto.photoId === photo.photoId) == null
      )
      .map((myPhoto) => ({
        type: 'removed',
        photoId: myPhoto.photoId,
      }));

    const movedPhotos: PhotoMoved[] = myPhotos
      .filter(
        (myPhoto, myPhotoIndex) =>
          photos.findIndex((photo) => photo.photoId === myPhoto.photoId) !==
          myPhotoIndex
      )
      .map((myPhoto) => ({
        type: 'moved',
        photoId: myPhoto.photoId,
        position: myPhotos.indexOf(myPhoto) + 1,
      }));

    return [...addedPhotos, ...removedPhotos, ...movedPhotos];
  }, [photos, myPhotos]);
  const [error, setError] = useState<string>();

  const onPhotosChosen = async (files: File[]) => {
    setMyPhotos((currentPhotos) => [
      ...currentPhotos,
      ...files.map((file) => ({
        photoId: `new-${uuidv4()}`,
        photoName: file.name,
        photoSize: file.size,
        photoUrl: URL.createObjectURL(file),
        isNew: true,
        file,
      })),
    ]);
  };

  const onRemovePhotoClick = async (listingPhotoId: string) => {
    setMyPhotos((currentPhotos) => [
      ...currentPhotos.filter((photo) => photo.photoId !== listingPhotoId),
    ]);
  };

  const onCancelClicked = () => {
    setError(undefined);
    setMyPhotos(photos.map((photo) => ({ ...photo, isNew: false })));
  };

  const onSaveClicked = async () => {
    setError(undefined);

    // 1. upload photos
    const changesToAdd = changes.filter(
      (change) => change.type === 'added'
    ) as PhotoAdded[];

    const filesToAdd = changesToAdd.map((x) => x.file);
    const fileAliases = new Map<string, string>([]);

    if (filesToAdd.length) {
      let uploadResult: UploadListingPhotosSuccessResult | undefined;

      try {
        uploadResult = await uploadListingPhotos({
          listingId,
          photoFiles: filesToAdd,
        });
      } catch (err) {
        setError(
          'Error uploading the new photos. Ensure that each file is under 2MB.'
        );
        return;
      }

      if (uploadResult == null) {
        // todo
        // eslint-disable-next-line no-alert
        alert('Error uploading photos');
        return;
      }

      const addedPhotos = uploadResult!.photos;

      // update photoId for each added photo
      changesToAdd.forEach((changeToAdd, changeToAddIndex) => {
        setMyPhotos((myPhotosToUpdate) => {
          const photoIndex = myPhotosToUpdate.findIndex(
            (myPhoto) => myPhoto.photoId === changeToAdd.photoId
          )!;
          const updatedPhoto: MyPhotoExisting = {
            photoId: addedPhotos[changeToAddIndex].photoId,
            photoName: addedPhotos[changeToAddIndex].photoName,
            photoSize: addedPhotos[changeToAddIndex].photoSize,
            photoUrl: addedPhotos[changeToAddIndex].photoUrl,
            isNew: false,
          };
          fileAliases.set(
            changeToAdd.photoId,
            addedPhotos[changeToAddIndex].photoId
          );

          const updatedMyPhotosToUpdate = [...myPhotosToUpdate];
          updatedMyPhotosToUpdate[photoIndex] = updatedPhoto;

          return updatedMyPhotosToUpdate;
        });
      });

      await changed();
    }

    // 2. remove photos
    const photoIdsToRemove = (
      changes.filter((change) => change.type === 'removed') as PhotoRemoved[]
    ).map((changeToRemove) => changeToRemove.photoId);

    if (photoIdsToRemove.length) {
      await Promise.all(
        photoIdsToRemove.map((photoIdToRemove) =>
          removeListingPhoto({
            listingId,
            listingPhotoId: photoIdToRemove,
          })
        )
      );

      await changed();
    }

    // 3. move photos
    const listingPhotosPositions = (
      changes.filter((change) => change.type === 'moved') as PhotoMoved[]
    ).map((movedPhoto) => ({
      photoId: fileAliases.has(movedPhoto.photoId)
        ? fileAliases.get(movedPhoto.photoId)!
        : movedPhoto.photoId,
      position: movedPhoto.position,
    }));

    if (listingPhotosPositions.length) {
      await changeListingPhotosPositions(listingId, {
        listingPhotosPositions,
      });

      await changed();
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex == null || hoverIndex == null) return;

      const dragCard = myPhotos[dragIndex];

      const newPhotos = [...myPhotos];
      newPhotos.splice(dragIndex, 1);
      newPhotos.splice(hoverIndex, 0, dragCard);
      setMyPhotos(newPhotos);
    },
    [myPhotos]
  );

  return (
    <div className="bg-white">
      <Heading title="Change Listing Photos">
        {changes.length !== 0 && (
          <>
            <button
              type="button"
              className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
              onClick={onCancelClicked}
            >
              Reset
            </button>

            <button
              type="button"
              className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
              onClick={onSaveClicked}
            >
              Save
            </button>
          </>
        )}
      </Heading>

      {error && (
        <div className="flex text-red-500 bg-red-200 m-3 border-red-400 border p-4">
          <div className="flex-1">{error}</div>
          <button
            type="button"
            title="Clear error"
            className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
            onClick={() => setError(undefined)}
          >
            <XIcon height={24} />
          </button>
        </div>
      )}

      <DndProvider backend={HTML5Backend}>
        <div className="flex flex-wrap m-5">
          {myPhotos.map((photo, photoIndex) => (
            <Photo
              key={photo.photoId}
              photoIndex={photoIndex}
              photoId={photo.photoId}
              photoSize={photo.photoSize}
              photoUrl={photo.photoUrl}
              removePhotoClick={() => onRemovePhotoClick(photo.photoId)}
              moveCard={moveCard}
            />
          ))}
        </div>
      </DndProvider>

      <div className="p-8 flex justify-center">
        <div className="h-72 w-full max-w-4xl">
          <FileDropZone
            label="photos"
            fileTypes={[
              { label: 'JPEG', mimeType: 'image/jpeg' },
              { label: 'PNG', mimeType: 'image/png' },
            ]}
            filesAdded={onPhotosChosen}
          />
        </div>
      </div>
      {/* <h2>Changes</h2>
      <pre>{JSON.stringify(changes, undefined, 2)}</pre>
      <h2>Photos</h2>
      <pre>{JSON.stringify(photos, undefined, 2)}</pre>
      <h2>My Photos</h2>
      <pre>{JSON.stringify(myPhotos, undefined, 2)}</pre> */}
    </div>
  );
};
