import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ConfirmMobileRequest } from './api-types';
import { ValidationError } from './common';

export async function confirmMobile(
  request: ConfirmMobileRequest
): Promise<void> {
  try {
    await apiClient.post<void>(`account/confirm-mobile`, {
      code: request.code,
    });
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        throw new ValidationError(
          'Verification code is incorrect',
          axiosError.response.data.errors
        );
      }
    }
    throw err;
  }
}
