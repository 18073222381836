import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { getListings, ListingStatus, ListingType } from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { TextAlert } from '../../../components/TextAlert';

export const Listings: React.VFC = () => {
  const { isLoading, isError, error, data } = useQuery('listings', getListings);
  const history = useHistory();

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return (
      <TextAlert
        label={
          (error as Error).message ||
          'Unexpected error occurred loading the listings'
        }
      />
    );
  }

  return (
    <div>
      <Breadcrumbs items={[{ label: 'Listings' }]} />

      <Heading title="Listings">
        <div>
          <Link
            className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
            to="/listings/create"
          >
            Add
          </Link>
        </div>
      </Heading>

      {data?.length !== 0 && (
        <div className="bg-white shadow-md rounded">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Address</th>
                <th className="py-3 px-6 text-center w-36 hidden md:table-cell">
                  Type
                </th>
                <th className="py-3 px-6 text-left w-20 hidden md:table-cell">
                  Rooms
                </th>
                <th className="py-3 px-6 text-left w-20 hidden md:table-cell">
                  Baths
                </th>
                <th className="py-3 px-6 text-left w-20 hidden lg:table-cell">
                  Floor
                </th>
                <th className="py-3 px-6 text-left w-20 hidden lg:table-cell">
                  Land
                </th>
                <th className="py-3 px-6 text-center w-36 hidden md:table-cell">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {data?.map((listing, index) => (
                <tr
                  key={listing.listingId}
                  className={`border-b border-gray-200 ${
                    index % 2 === 1 ? 'bg-gray-50 ' : ''
                  }hover:bg-gray-100 cursor-pointer`}
                  onClick={() => history.push(`/listings/${listing.listingId}`)}
                >
                  <td className="py-3 px-6 text-left font-medium">
                    <div>{listing.property.propertyAddress}</div>

                    <div className="md:hidden font-light mt-4">
                      <div className="p-1">
                        Type:{' '}
                        <span
                          className={`px-2 rounded-full text-xs ${
                            (listing.listingType === ListingType.Sale &&
                              'bg-red-200 text-red-600') ||
                            (listing.listingType === ListingType.Rent &&
                              'bg-blue-200 text-blue-600')
                          }`}
                        >
                          {listing.listingType}
                        </span>
                      </div>
                      <div className="p-1">
                        Status:{' '}
                        <span
                          className={`px-2 rounded-full text-xs ${
                            (listing.listingStatus === ListingStatus.Draft &&
                              'bg-blue-200 text-blue-600') ||
                            (listing.listingStatus === ListingStatus.Active &&
                              'bg-green-200 text-green-600') ||
                            (listing.listingStatus === ListingStatus.Closed &&
                              'bg-gray-200 text-gray-600')
                          }`}
                        >
                          {listing.listingStatus}
                        </span>
                      </div>
                      <div className=" flex flex-row flex-wrap">
                        {listing.property.bedrooms && (
                          <div className="flex p-1">
                            Bedrooms: {listing.property.bedrooms}
                          </div>
                        )}
                        {listing.property.bathrooms && (
                          <div className="flex p-1">
                            Bathrooms: {listing.property.bathrooms}
                          </div>
                        )}
                        {listing.property.floorArea && (
                          <div className="flex p-1">
                            Floor area: {listing.property.floorArea}
                          </div>
                        )}
                        {listing.property.landArea && (
                          <div className="flex p-1">
                            Land area: {listing.property.landArea}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-center hidden md:table-cell">
                    <span
                      className={`py-1 px-3 rounded-full text-xs ${
                        (listing.listingType === ListingType.Sale &&
                          'bg-red-200 text-red-600') ||
                        (listing.listingType === ListingType.Rent &&
                          'bg-blue-200 text-blue-600')
                      }`}
                    >
                      {listing.listingType}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-left hidden md:table-cell">
                    {listing.property.bedrooms}
                  </td>
                  <td className="py-3 px-6 text-left hidden md:table-cell">
                    {listing.property.bathrooms}
                  </td>
                  <td className="py-3 px-6 text-left hidden lg:table-cell">
                    {listing.property.floorArea}
                  </td>
                  <td className="py-3 px-6 text-left hidden lg:table-cell">
                    {listing.property.landArea}
                  </td>
                  <td className="py-3 px-6 text-center hidden md:table-cell">
                    <span
                      className={`py-1 px-3 rounded-full text-xs ${
                        (listing.listingStatus === ListingStatus.Draft &&
                          'bg-blue-200 text-blue-600') ||
                        (listing.listingStatus === ListingStatus.Active &&
                          'bg-green-200 text-green-600') ||
                        (listing.listingStatus === ListingStatus.Closed &&
                          'bg-gray-200 text-gray-600')
                      }`}
                    >
                      {listing.listingStatus}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
