/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useCallback, FormEvent } from 'react';
import { useMutation } from 'react-query';
import { Redirect } from 'react-router-dom';
import {
  PropertyAddress,
  createListing,
  CreateListingRequest,
  ListingType,
  ValidationError,
} from '../../../api';
import { AddressSelect } from '../../../components/AddressSelect';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';

type FormValidationErrorProps = {
  error: Error | ValidationError;
};

const FormValidationError: React.VFC<FormValidationErrorProps> = ({
  error,
}: FormValidationErrorProps) => {
  const { isValidationError } = error as ValidationError;
  const validationError = (error as ValidationError).errors;

  return (
    <>
      <div className="p-2 my-2 bg-red-400 text-white">
        {isValidationError &&
          Object.keys(validationError).map((errorKey) => (
            <div>
              <strong>{errorKey}:</strong>
              <br />
              <ul>
                {validationError[errorKey].map((message) => (
                  <li>{message}</li>
                ))}
              </ul>
            </div>
          ))}

        {!isValidationError && error.message}
      </div>
    </>
  );
};

export const ListingCreate: React.VFC = () => {
  const [address, setAddress] = useState<PropertyAddress | undefined>();
  const [listingTitle, setListingTitle] = useState('');
  const [listingType, setListingType] = useState<ListingType>();
  const [bedrooms, setBedrooms] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [floorArea, setFloorArea] = useState<number>();
  const [landArea, setLandArea] = useState<number>();
  const createListingMutation = useMutation((listing: CreateListingRequest) =>
    createListing(listing)
  );

  const onAddressChosen = useCallback(
    (chosenAddress: PropertyAddress | undefined) => {
      setAddress(chosenAddress);
    },
    [setAddress]
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createListingMutation.mutate({
      propertyAddressId: address!.id,
      listingTitle,
      listingType: listingType!,
      bedrooms,
      bathrooms,
      floorArea,
      landArea,
    });
  };

  if (createListingMutation.isSuccess) {
    return <Redirect to="/listings" />;
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            label: 'Create Listing',
          },
        ]}
      />

      <Heading title="Create Listing" />

      <form
        onSubmit={onSubmit}
        autoComplete="off"
        className="bg-white border p-4"
      >
        <div className="my-3 w-full md:w-1/2">
          <label htmlFor="propertyAddress">Property Address *</label>

          <AddressSelect
            id="propertyAddress"
            address={address}
            addressChosen={onAddressChosen}
          />
        </div>

        {address != null && (
          <>
            <div className="my-3 w-full md:w-1/2">
              <label htmlFor="listingTitle">Listing Title</label>
              <input
                id="listingTitle"
                name="listingTitle"
                type="text"
                className="block p-2 border w-full"
                value={listingTitle}
                onChange={(event) => setListingTitle(event.target.value)}
              />
            </div>

            <div className="my-3 w-full md:w-1/2">
              <label htmlFor="listingType">Listing Type *</label>

              <label className="block p-2 w-full cursor-pointer">
                <input
                  type="radio"
                  name="listingType"
                  value={ListingType.Sale}
                  checked={listingType === ListingType.Sale}
                  onChange={(event) =>
                    setListingType(
                      (event.target as HTMLInputElement)
                        .value as ListingType.Sale
                    )
                  }
                />{' '}
                Sale
              </label>
              <label className="block p-2 w-full cursor-pointer">
                <input
                  type="radio"
                  name="listingType"
                  value={ListingType.Rent}
                  checked={listingType === ListingType.Rent}
                  onChange={(event) =>
                    setListingType(
                      (event.target as HTMLInputElement)
                        .value as ListingType.Rent
                    )
                  }
                />{' '}
                Rent
              </label>
            </div>

            <div className="flex flex-wrap">
              <div className="my-3 w-24 mr-2">
                <label htmlFor="bedrooms">Bedrooms</label>

                <input
                  type="text"
                  name="bedrooms"
                  className="block p-2 border w-full"
                  autoComplete="off"
                  value={bedrooms == null ? '' : bedrooms}
                  onChange={(event) => {
                    const { value } = event.target as HTMLInputElement;
                    let newValue =
                      value === '' ? undefined : parseInt(value, 10);
                    newValue = Number.isNaN(newValue) ? undefined : newValue;
                    setBedrooms(newValue);
                  }}
                />
              </div>

              <div className="my-3 w-24 mr-2">
                <label htmlFor="bathrooms">Bathrooms</label>

                <input
                  type="text"
                  name="bathrooms"
                  className="block p-2 border w-full"
                  autoComplete="off"
                  value={bathrooms == null ? '' : bathrooms}
                  onChange={(event) => {
                    const { value } = event.target as HTMLInputElement;
                    let newValue =
                      value === '' ? undefined : parseInt(value, 10);
                    newValue = Number.isNaN(newValue) ? undefined : newValue;
                    setBathrooms(newValue);
                  }}
                />
              </div>

              <div className="my-3 w-24 mr-2">
                <label htmlFor="floorArea">Floor Area</label>

                <input
                  type="text"
                  name="floorArea"
                  className="block p-2 border w-full"
                  autoComplete="off"
                  value={floorArea == null ? '' : floorArea}
                  onChange={(event) => {
                    const { value } = event.target as HTMLInputElement;
                    let newValue =
                      value === '' ? undefined : parseInt(value, 10);
                    newValue = Number.isNaN(newValue) ? undefined : newValue;
                    setFloorArea(newValue);
                  }}
                />
              </div>

              <div className="my-3 w-24 mr-2">
                <label htmlFor="landArea">Land Area</label>

                <input
                  type="text"
                  name="landArea"
                  className="block p-2 border w-full"
                  autoComplete="off"
                  value={landArea == null ? '' : landArea}
                  onChange={(event) => {
                    const { value } = event.target as HTMLInputElement;
                    let newValue =
                      value === '' ? undefined : parseInt(value, 10);
                    newValue = Number.isNaN(newValue) ? undefined : newValue;
                    setLandArea(newValue);
                  }}
                />
              </div>
            </div>

            {createListingMutation.isError && (
              <FormValidationError
                error={createListingMutation.error as ValidationError}
              />
            )}

            <button
              type="submit"
              className="block bg-gray-700 text-white w-1/2 p-2"
              disabled={createListingMutation.isLoading}
            >
              Create
            </button>
          </>
        )}
      </form>
    </div>
  );
};
