import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { getListingDetails } from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { TextAlert } from '../../../components/TextAlert';

export const ListingVisitors: React.VFC = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const listingQuery = useQuery(['listing-details', listingId], () =>
    getListingDetails(listingId)
  );

  if (listingQuery.isLoading) {
    return <span>Loading...</span>;
  }

  if (listingQuery.isError) {
    return (
      <TextAlert
        label={
          (listingQuery.error as Error).message ||
          'Unexpected error occurred loading the listings'
        }
      />
    );
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            route: `/listings/${listingId}`,
            label:
              listingQuery.data?.property?.propertyAddress || 'Listing Details',
          },
          { label: 'Visitors' },
        ]}
      />

      <Heading title="Listing Visitors" />

      {listingQuery.data && (
        <div className="bg-white shadow-md rounded">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left w-20">Date</th>
                <th className="py-3 px-6 text-left w-28">Name</th>
                <th className="py-3 px-6 text-left w-12 hidden md:table-cell">
                  Visits
                </th>
                <th className="py-3 px-6 text-left w-14 hidden md:table-cell">
                  Mobile
                </th>
                <th className="py-3 px-6 text-left w-14 hidden lg:table-cell">
                  Email
                </th>
                <th className="py-3 px-6 text-left w-20 hidden lg:table-cell">
                  Address
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {(listingQuery.data.visitors || []).map((visitor, index) => (
                <>
                  <tr
                    key={visitor.visitorId}
                    className={`border-b-0 border-gray-200 md:border-b ${
                      index % 2 === 1 ? 'bg-gray-50 ' : ''
                    }`}
                  >
                    <td className="py-3 px-6 text-left font-medium whitespace-nowrap">
                      {format(parseISO(visitor.visitedAt), 'yyyy-MM-dd HH:MM')}
                    </td>
                    <td className="py-3 px-6 text-left font-medium overflow-x-hidden overflow-ellipsis">
                      {visitor.firstName} {visitor.lastName}
                    </td>
                    <td className="py-3 px-6 text-left hidden md:table-cell">
                      {visitor.numberOfVisits}
                    </td>
                    <td className="py-3 px-6 text-left hidden md:table-cell">
                      <a
                        href={`tel:${visitor.mobile}`}
                        className="text-blue-400 underline"
                      >
                        {visitor.mobile}
                      </a>
                    </td>
                    <td className="py-3 px-6 text-left hidden lg:table-cell overflow-x-hidden overflow-ellipsis">
                      <a
                        href={`mailto:${visitor.email}`}
                        className="text-blue-400 underline"
                      >
                        {visitor.email}
                      </a>
                    </td>
                    <td className="py-3 px-6 text-left hidden lg:table-cell overflow-x-hidden overflow-ellipsis">
                      {visitor.address}
                    </td>
                  </tr>
                  <tr
                    className={`border-b border-gray-200 lg:hidden font-light mt-4 ${
                      index % 2 === 1 ? 'bg-gray-50 ' : ''
                    }`}
                  >
                    <td colSpan={100} className="py-3 px-6">
                      <div className="p-1 md:hidden">
                        <strong>Visits:</strong> {visitor.numberOfVisits}
                      </div>
                      <div className="p-1 md:hidden">
                        <strong>Mobile:</strong>{' '}
                        {visitor.mobile != null && (
                          <a
                            href={`tel:${visitor.mobile}`}
                            className="text-blue-400 underline"
                          >
                            {visitor.mobile}
                          </a>
                        )}
                        {visitor.mobile == null && <span>None</span>}
                      </div>
                      <div className="p-1">
                        <strong>Email:</strong>{' '}
                        <a
                          href={`mailto:${visitor.email}`}
                          className="text-blue-400 underline"
                        >
                          {visitor.email}
                        </a>
                      </div>
                      <div className="p-1">
                        <strong>Address:</strong> {visitor.address}
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          {(listingQuery.data.visitors || []).length === 0 && (
            <div className="p-3">
              <TextAlert label="No visitors" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
