import { XIcon } from '@heroicons/react/outline';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getListingDetails, updateListingDetails } from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Heading } from '../../../components/Heading';
import { TextAlert } from '../../../components/TextAlert';
import { Form, FormData, ListingContact } from './Form';

export const Layout: React.VFC = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const listingQuery = useQuery(['listing-details', listingId], () =>
    getListingDetails(listingId)
  );
  const [formData, setFormData] = useState<FormData>({
    contact1: undefined,
    contact2: undefined,
  });
  const [formDataChanges, setFormDataChanges] = useState<
    Map<keyof FormData, ListingContact | undefined | null>
  >(new Map());
  const hasFormChanges = useMemo(
    () => formDataChanges.size > 0,
    [formDataChanges]
  );
  const [error, setError] = useState<string>();

  const onFormChangesChanged = useCallback(
    (value: Map<keyof FormData, ListingContact | undefined | null>): void =>
      setFormDataChanges(value),
    []
  );

  const onFormDataChanged = useCallback(
    (value: FormData): void => setFormData(value),
    []
  );

  if (listingQuery.isLoading) {
    return <span>Loading...</span>;
  }

  if (listingQuery.isError) {
    return (
      <TextAlert
        label={
          (listingQuery.error as Error).message ||
          'Unexpected error occurred loading the listing'
        }
      />
    );
  }

  const onCancelClicked = () => {
    setFormData({
      contact1:
        listingQuery.data?.primaryAgent == null
          ? undefined
          : {
              userId: listingQuery.data?.primaryAgent.userId,
              firstName: listingQuery.data?.primaryAgent.firstName,
              lastName: listingQuery.data?.primaryAgent.lastName,
              mobile: listingQuery.data?.primaryAgent.mobile,
              phoneNumber:
                listingQuery.data?.primaryAgent.phoneNumber || undefined,
              email: listingQuery.data?.primaryAgent.email,
            },
      contact2:
        listingQuery.data?.secondaryAgent == null
          ? undefined
          : {
              userId: listingQuery.data?.secondaryAgent.userId,
              firstName: listingQuery.data?.secondaryAgent.firstName,
              lastName: listingQuery.data?.secondaryAgent.lastName,
              mobile: listingQuery.data?.secondaryAgent.mobile,
              phoneNumber:
                listingQuery.data?.secondaryAgent.phoneNumber || undefined,
              email: listingQuery.data?.secondaryAgent.email,
            },
    });
  };

  const onSaveClicked = async () => {
    setError(undefined);

    try {
      const contact1Change = formDataChanges.get('contact1');
      const contact2Change = formDataChanges.get('contact2');
      const changes = {
        contact1: contact1Change === null ? null : contact1Change?.userId,
        contact2: contact2Change === null ? null : contact2Change?.userId,
      };

      await updateListingDetails({
        listingId,
        changes,
      });
      await listingQuery.refetch();
    } catch {
      setError('Error updating listing details.');
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            route: `/listings/${listingId}`,
            label:
              listingQuery.data != null
                ? listingQuery.data.property.propertyAddress
                : 'Listing Details',
          },
          { label: 'Edit Listing Contacts' },
        ]}
      />

      <div className="bg-white">
        <Heading title="Edit Listing Contacts">
          {hasFormChanges && (
            <>
              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onCancelClicked}
              >
                Reset
              </button>

              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onSaveClicked}
              >
                Save
              </button>
            </>
          )}
        </Heading>

        {error && (
          <div className="flex text-red-500 bg-red-200 m-3 border-red-400 border p-4">
            <div className="flex-1">{error}</div>
            <button
              type="button"
              title="Clear error"
              className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
              onClick={() => setError(undefined)}
            >
              <XIcon height={24} />
            </button>
          </div>
        )}

        {listingQuery.data != null && (
          <div className="p-8">
            <Form
              listing={listingQuery.data}
              formData={formData}
              onFormDataChange={onFormDataChanged}
              onFormChangesChange={onFormChangesChanged}
            />
          </div>
        )}
      </div>
    </>
  );
};
