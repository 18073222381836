import { useMemo } from 'react';
import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';
import { useSessionContext } from '../contexts/SessionContext';
import {
  AccountOnboardingStatus,
  getAccountOnboardingStatus,
} from '../helpers/onboarding-status';
import { Header } from './Header';
import { NavigationMenu } from './NavigationMenu';

export type AppLayoutProps = {
  children: React.ReactNode;
};

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
}: AppLayoutProps) => {
  const [session, setSession] = useSessionContext();
  const isAuthorised = useMemo(() => {
    if (session.isAuthenticated === false || session.accountDetails == null) {
      return false;
    }

    const accountOnboardingStatus = getAccountOnboardingStatus(
      session.accountDetails
    );

    return accountOnboardingStatus === AccountOnboardingStatus.Complete;
  }, [session]);

  const onLogoutClick = () => {
    ReactGA.event({
      category: 'Auth',
      action: 'Logout Clicked',
    });

    localStorage.removeItem('access_token');
    localStorage.removeItem('mobile_verified');
    localStorage.removeItem('account_details');

    setSession((s) => ({
      ...s,
      isAuthenticated: false,
      isEmailVerified: undefined,
      isMobileVerified: undefined,
    }));
  };

  return (
    <>
      {isAuthorised && (
        <div className="flex flex-col min-h-screen">
          <Header
            user={{
              firstName:
                session.accountDetails?.userDetails.firstName || 'John',
              lastName: session.accountDetails?.userDetails.lastName || 'Doe',
            }}
            logoutClick={onLogoutClick}
          />

          <div
            className="flex flex-grow"
            style={{ backgroundColor: '#f5f6fa' }}
          >
            <div
              className="hidden sm:block flex-column bg-white p-1 w-36"
              style={{
                boxShadow: '0 2px 4px 0 rgba(170, 163, 163, 0.5)',
                minWidth: '9rem',
              }}
            >
              <NavigationMenu />
            </div>

            <main className="flex-grow">
              <div className="px-3 py-1">{children}</div>
            </main>
          </div>
        </div>
      )}
      {!isAuthorised && <>{children}</>}
      <ToastContainer />
    </>
  );
};
