import {
  XIcon,
  ExternalLinkIcon,
  MenuAlt4Icon,
} from '@heroicons/react/outline';
import { useMemo, useRef } from 'react';
import { useDrop, DropTargetMonitor, useDrag } from 'react-dnd';

type DocumentItem = {
  documentIndex: number;
  documentId: string;
  documentName: string;
  documentSize: number;
  documentUrl: string;
};

export type DocumentProps = DocumentItem & {
  removeClick: VoidFunction;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};

const numberFormat = Intl.NumberFormat();

export const Document: React.VFC<DocumentProps> = ({
  documentIndex,
  documentId,
  documentName,
  documentSize,
  documentUrl,
  removeClick,
  moveCard,
}: DocumentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const documentSizeString = useMemo(() => {
    if (documentSize < 1024) {
      return `${documentSize} B`;
    }

    return `${numberFormat.format(Math.ceil(documentSize / 1024))} KB`;
  }, [documentSize]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DocumentItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.documentIndex;
      const hoverIndex = documentIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const rect = ref.current?.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const { x: mouseX, y: mouseY } = clientOffset || { x: 0, y: 0 };

      const hit =
        mouseY > rect.top &&
        mouseY < rect.bottom &&
        mouseX > rect.left &&
        mouseX < rect.right;
      const miss = !hit;

      if (miss) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      item.documentIndex = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'card',
      item: { id: documentId, index: documentIndex },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className="m-3 border relative cursor-move hover:text-blue-500 hover:border-blue-500"
      style={{ minWidth: 300, opacity }}
    >
      <div className="flex items-center h-14">
        <div className="h-full w-10 mr-2 flex justify-center items-center">
          <MenuAlt4Icon height={24} />
        </div>

        <div className="flex-1 overflow-x-hidden whitespace-nowrap overflow-ellipsis">
          {documentName}
        </div>

        <div className="flex h-full">
          <div className="flex justify-center items-center px-2">
            {documentSizeString}
          </div>
          <a
            href={documentUrl}
            target="_blank"
            rel="noreferrer"
            title="Open document"
            className="cursor-pointer bg-white text-gray-500 hover:bg-blue-500 hover:text-white h-full w-10 flex justify-center items-center"
          >
            <ExternalLinkIcon height={24} />
          </a>
          <button
            type="button"
            title="Remove document"
            className="cursor-pointer bg-white text-gray-500 hover:bg-blue-500 hover:text-white h-full w-10 flex justify-center items-center"
            onClick={removeClick}
          >
            <XIcon height={24} />
          </button>
        </div>
      </div>
    </div>
  );
};
