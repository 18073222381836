import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { AccountQueryResult } from '../api';

export type Session = {
  isAuthenticated: boolean;
  accountDetails: AccountQueryResult | undefined;
  redirectPath: string;
};

const getLocalStorageObject = <T,>(key: string): T | undefined => {
  const stringValue = localStorage.getItem(key);
  return stringValue == null ? undefined : JSON.parse(stringValue);
};

export const getInitialSession = (): Session => {
  const value: Session = {
    isAuthenticated: localStorage.getItem('access_token') != null,
    accountDetails: getLocalStorageObject('account_details'),
    redirectPath: '/',
  };
  return value;
};

export const SessionContext = createContext<
  [Session, Dispatch<SetStateAction<Session>>]
>([
  {
    isAuthenticated: false,
    accountDetails: undefined,
    redirectPath: '',
  },
  () => {},
]);

export const useSessionContext = (): [
  Session,
  Dispatch<SetStateAction<Session>>
] => useContext(SessionContext);

export type SessionContextProviderProps = {
  children: React.ReactNode;
};

export const SessionContextProvider: React.FC<SessionContextProviderProps> = ({
  children,
}: SessionContextProviderProps) => {
  const [sessionState, setSessionState] = useState(getInitialSession);
  const defaultSessionContext: [Session, typeof setSessionState] = [
    sessionState,
    setSessionState,
  ];

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {children}
    </SessionContext.Provider>
  );
};
