export type HeadingProps = {
  title: string;
  children?: React.ReactNode;
};

export const Heading: React.FC<HeadingProps> = ({
  title,
  children,
}: HeadingProps) => {
  return (
    <div className="flex flex-wrap pr-7 bg-blue-500 text-white items-center h-16">
      <h1 className="flex-1 p-4 text-2xl overflow-hidden whitespace-nowrap overflow-ellipsis">
        {title}
      </h1>

      {children}
    </div>
  );
};
