import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Session } from '../contexts/SessionContext';
import {
  AccountOnboardingStatus,
  getAccountOnboardingStatus,
} from '../helpers/onboarding-status';

export type ProtectedRouteProps = {
  session: Session;
} & RouteProps;

export const ProtectedRoute: React.VFC<ProtectedRouteProps> = ({
  session,
  ...routeProps
}: ProtectedRouteProps) => {
  if (session.isAuthenticated === false || session.accountDetails == null) {
    return <Redirect to="/login" />;
  }

  const accountOnboardingStatus = getAccountOnboardingStatus(
    session.accountDetails
  );

  if (
    accountOnboardingStatus ===
    AccountOnboardingStatus.EmailVerificationRequired
  ) {
    return <Redirect to="/verify-email" />;
  }

  if (accountOnboardingStatus === AccountOnboardingStatus.MobileRequired) {
    return <Redirect to="/provide-mobile" />;
  }

  if (
    accountOnboardingStatus ===
    AccountOnboardingStatus.MobileVerificationRequired
  ) {
    return <Redirect to="/verify-mobile" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...routeProps} />;
};
