import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactGA from 'react-ga';
import App from './App';
import reportWebVitals from './reportWebVitals';

const TRACKING_ID = 'UA-196437533-1';

ReactGA.initialize(TRACKING_ID, {
  debug: process.env.NODE_ENV !== 'production',
  testMode: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(({ name, value }) => {
  ReactGA.timing({
    category: 'Web Vitals',
    variable: name,
    value: name === 'CLS' ? value * 1000 : value,
  });
});
