import React, { useEffect, useMemo } from 'react';
import { ListingDetails } from '../../../api';
import { DescriptionList } from '../../../components/DescriptionList';
import { TextAlert } from '../../../components/TextAlert';
import { AgentSelect } from './AgentSelect';

export type ListingContact = {
  userId: string;
  firstName: string;
  lastName: string;
  mobile: string;
  phoneNumber: string | undefined;
  email: string;
};

export type FormData = {
  contact1: ListingContact | undefined | null;
  contact2: ListingContact | undefined | null;
};

export type FormProps = {
  listing: ListingDetails;
  formData: FormData;
  onFormDataChange: (value: FormData) => void;
  onFormChangesChange: (
    value: Map<keyof FormData, ListingContact | undefined | null>
  ) => void;
};

export const Form: React.VFC<FormProps> = ({
  listing,
  formData,
  onFormDataChange,
  onFormChangesChange,
}: FormProps) => {
  const changes = useMemo(() => {
    const changesMap = new Map<
      keyof FormData,
      ListingContact | undefined | null
    >();
    if (listing.primaryAgent?.userId !== formData.contact1?.userId) {
      changesMap.set('contact1', formData.contact1);
    }
    if (listing.secondaryAgent?.userId !== formData.contact2?.userId) {
      changesMap.set('contact2', formData.contact2);
    }
    return changesMap;
  }, [listing, formData]);

  useEffect(() => {
    const newFormData: FormData = {
      contact1:
        listing.primaryAgent == null
          ? undefined
          : {
              userId: listing.primaryAgent.userId,
              firstName: listing.primaryAgent.firstName,
              lastName: listing.primaryAgent.lastName,
              email: listing.primaryAgent.email,
              mobile: listing.primaryAgent.mobile,
              phoneNumber: listing.primaryAgent.phoneNumber || undefined,
            },
      contact2:
        listing.secondaryAgent == null
          ? undefined
          : {
              userId: listing.secondaryAgent.userId,
              firstName: listing.secondaryAgent.firstName,
              lastName: listing.secondaryAgent.lastName,
              email: listing.secondaryAgent.email,
              mobile: listing.secondaryAgent.mobile,
              phoneNumber: listing.secondaryAgent.phoneNumber || undefined,
            },
    };

    onFormDataChange(newFormData);
  }, [listing, onFormDataChange]);

  useEffect(() => {
    onFormChangesChange(changes);
  }, [changes, onFormChangesChange]);

  return (
    <>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 p-2 max-w-lg">
          <h3 className="p-3 text-center border">Primary Contact</h3>

          <div className="border border-t-0">
            <div className="p-3">
              <AgentSelect
                id="contract-1-input"
                agent={
                  formData.contact1 == null
                    ? formData.contact1
                    : {
                        id: formData.contact1.userId,
                        firstName: formData.contact1.firstName,
                        lastName: formData.contact1.lastName,
                        mobile: formData.contact1.mobile,
                        phoneNumber: formData.contact1.phoneNumber,
                        email: formData.contact1.email,
                      }
                }
                agentChosen={(x) => {
                  onFormDataChange({
                    ...formData,
                    contact1:
                      x == null
                        ? x
                        : {
                            userId: x.id,
                            firstName: x.firstName,
                            lastName: x.lastName,
                            mobile: x.mobile,
                            phoneNumber: x.phoneNumber,
                            email: x.email,
                          },
                  });
                }}
              />
            </div>

            {formData.contact1 && (
              <DescriptionList
                items={[
                  {
                    label: 'name',
                    value: `${formData.contact1?.firstName} ${formData.contact1?.lastName}`,
                  },
                  {
                    label: 'mobile',
                    value: formData.contact1?.mobile || '',
                  },
                  {
                    label: 'phone',
                    value: formData.contact1?.phoneNumber || '',
                  },
                  {
                    label: 'email',
                    value: formData.contact1?.email || '',
                  },
                ]}
              />
            )}
            {formData.contact1 == null && (
              <TextAlert label="No primary contact" />
            )}
          </div>
        </div>

        <div className="flex-1 p-2 max-w-lg">
          <h3 className="p-3 text-center border">Secondary Contact</h3>

          <div className="border border-t-0">
            <div className="p-3">
              <AgentSelect
                id="contract-2-input"
                agent={
                  formData.contact2 == null
                    ? formData.contact2
                    : {
                        id: formData.contact2.userId,
                        firstName: formData.contact2.firstName,
                        lastName: formData.contact2.lastName,
                        mobile: formData.contact2.mobile,
                        phoneNumber: formData.contact2.phoneNumber,
                        email: formData.contact2.email,
                      }
                }
                agentChosen={(x) => {
                  onFormDataChange({
                    ...formData,
                    contact2:
                      x == null
                        ? x
                        : {
                            userId: x.id,
                            firstName: x.firstName,
                            lastName: x.lastName,
                            mobile: x.mobile,
                            phoneNumber: x.phoneNumber,
                            email: x.email,
                          },
                  });
                }}
              />
            </div>

            {formData.contact2 && (
              <DescriptionList
                items={[
                  {
                    label: 'name',
                    value: `${formData.contact2?.firstName} ${formData.contact2?.lastName}`,
                  },
                  {
                    label: 'mobile',
                    value: formData.contact2?.mobile || '',
                  },
                  {
                    label: 'phone',
                    value: formData.contact2?.phoneNumber || '',
                  },
                  {
                    label: 'email',
                    value: formData.contact2?.email || '',
                  },
                ]}
              />
            )}
            {formData.contact2 == null && (
              <TextAlert label="No secondary contact" />
            )}
          </div>
        </div>
      </div>
      {/* <pre>
        formData: {JSON.stringify(formData, undefined, 2)}
        <br />
        changes:{' '}
        {JSON.stringify(Object.fromEntries(changes.entries()), undefined, 2)}
      </pre> */}
    </>
  );
};
