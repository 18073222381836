export type DescriptionListProps = {
  items: Array<{ label: string; value: string }>;
};

export const DescriptionList: React.VFC<DescriptionListProps> = ({
  items,
}: DescriptionListProps) => {
  return (
    <dl>
      {items.map((item, index) => (
        <div
          key={item.label}
          className={`${
            index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
          } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
        >
          <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {item.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};
