/* eslint-disable jsx-a11y/img-redundant-alt */
import { XIcon } from '@heroicons/react/outline';
import { useMemo, useRef } from 'react';
import { useDrop, DropTargetMonitor, useDrag } from 'react-dnd';

type PhotoItem = {
  photoIndex: number;
  photoId: string;
  photoSize: number;
  photoUrl: string;
};

export type PhotoProps = PhotoItem & {
  removePhotoClick: VoidFunction;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};

const numberFormat = Intl.NumberFormat();

export const Photo: React.VFC<PhotoProps> = ({
  photoIndex,
  photoId,
  photoSize,
  photoUrl,
  removePhotoClick,
  moveCard,
}: PhotoProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const photoSizeString = useMemo(() => {
    if (photoSize < 1024) {
      return `${photoSize} B`;
    }

    return `${numberFormat.format(Math.ceil(photoSize / 1024))} KB`;
  }, [photoSize]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: PhotoItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.photoIndex;
      const hoverIndex = photoIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const rect = ref.current?.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const { x: mouseX, y: mouseY } = clientOffset || { x: 0, y: 0 };

      const hit =
        mouseY > rect.top &&
        mouseY < rect.bottom &&
        mouseX > rect.left &&
        mouseX < rect.right;
      const miss = !hit;

      if (miss) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      item.photoIndex = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'card',
      item: { id: photoId, index: photoIndex },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="m-3 border relative"
      style={{ minWidth: 300, opacity }}
      data-handler-id={handlerId}
    >
      <img
        className="h-52 cursor-move"
        src={photoUrl}
        alt={`Property photo ${photoIndex + 1}`}
      />

      <span className="absolute top-1 left-1 px-2 bg-white text-gray-500 rounded-full border">
        {`${photoIndex + 1}`}
      </span>

      <span className="absolute bottom-1 left-1 px-2 bg-white text-gray-500 rounded-full borde">
        {photoSizeString}
      </span>

      <button
        type="button"
        title="Remove photo"
        className="absolute top-1 right-1 bg-white rounded-full"
        onClick={removePhotoClick}
      >
        <XIcon
          className="text-gray-500 cursor-pointer hover:text-blue-500"
          height={24}
        />
      </button>
    </div>
  );
};
