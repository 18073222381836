import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Session } from '../../contexts/SessionContext';
import { Listings } from './Listings';

export const getBuyerRoutes = (session: Session): React.ReactNode[] => [
  <ProtectedRoute
    session={session}
    exact
    path="/listings"
    component={Listings}
  />,
];
