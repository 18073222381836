import { CameraIcon } from '@heroicons/react/outline';
import React from 'react';
import { ListingOverview } from '../../../api';
import { ReactComponent as BathIcon } from '../../../components/icons/bath-solid.svg';
import { ReactComponent as BedIcon } from '../../../components/icons/bed-solid.svg';
import { ReactComponent as ChartAreaIcon } from '../../../components/icons/chart-area-solid.svg';
import { ReactComponent as FloorPlanIcon } from '../../../components/icons/floor-plan.svg';

export type ListingCardProps = {
  listing: ListingOverview;
};

export const ListingCard: React.VFC<ListingCardProps> = ({
  listing,
}: ListingCardProps) => {
  return (
    <div className="border relative rounded-md m-5 w-96">
      {listing.listingPhotoUrl == null && (
        <div className="h-64 flex justify-center items-center border-b">
          <CameraIcon height={48} color="#ccc" />
        </div>
      )}
      {listing.listingPhotoUrl != null && (
        <img
          src={listing.listingPhotoUrl}
          alt="Listing"
          className="min-w-full h-64 w-full object-cover rounded-t-md"
        />
      )}

      {listing.primaryAgent && (
        <>
          <div className="flex border-b h-20">
            <div className="flex-1 px-3 relative">
              <div className="flex absolute -top-12">
                <img
                  src={listing.primaryAgent.avatarUrl || ''}
                  alt=""
                  className="w-16 h-16 rounded-full border border-white z-10"
                />

                {listing.secondaryAgent && (
                  <img
                    src={listing.secondaryAgent.avatarUrl || ''}
                    alt=""
                    className="w-16 h-16 rounded-full border border-white -ml-2"
                  />
                )}
              </div>

              <div className="text-xs font-bold py-5">
                {listing.primaryAgent.firstName} {listing.primaryAgent.lastName}
                {listing.secondaryAgent && (
                  <>
                    {' '}
                    &amp; {listing.secondaryAgent.firstName}{' '}
                    {listing.secondaryAgent.lastName}
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-1 justify-center text-sm">
              {listing.primaryAgent.agencyLogoUrl != null && (
                <div
                  className="flex items-center"
                  style={{
                    backgroundColor: `#${
                      listing.primaryAgent.agencyLogoBackgroundColour || 'fff'
                    }`,
                    marginBottom: -1,
                  }}
                >
                  <img
                    src={listing.primaryAgent.agencyLogoUrl}
                    alt={listing.primaryAgent.agencyName || 'Private seller'}
                  />
                </div>
              )}

              <div className="text-right">
                {listing.primaryAgent.agencyLogoUrl == null &&
                  listing.primaryAgent.agencyName}
              </div>
            </div>
          </div>

          <div className="py-5 px-3 font-bold">
            <div className="mb-2">{listing.property.propertyAddress}</div>

            <div className="flex flex-row text-xs">
              <div
                className="mx-2"
                style={{
                  color: listing.property.bedrooms != null ? '#356AC0' : 'grey',
                }}
              >
                <BedIcon className="inline w-4 h-4" />{' '}
                {listing.property.bedrooms}
              </div>
              <div
                className="mx-2"
                style={{
                  color:
                    listing.property.bathrooms != null ? '#356AC0' : 'grey',
                }}
              >
                <BathIcon className="inline w-4 h-4" />{' '}
                {listing.property.bathrooms}
              </div>
              <div
                className="mx-2"
                style={{
                  color:
                    listing.property.floorArea != null ? '#356AC0' : 'grey',
                }}
              >
                <FloorPlanIcon
                  className="inline w-4 h-4"
                  style={{
                    fill: 'currentcolor',
                  }}
                />{' '}
                {listing.property.floorArea}
              </div>
              <div
                className="mx-2"
                style={{
                  color: listing.property.landArea != null ? '#356AC0' : 'grey',
                }}
              >
                <ChartAreaIcon className="inline w-4 h-4" />{' '}
                {listing.property.landArea}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
