import { MinusIcon, PlusIcon, XIcon } from '@heroicons/react/outline';
import React, { ChangeEvent, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { getAccountDetails, uploadAccountPhoto } from '../../api';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Heading } from '../../components/Heading';
import { TextAlert } from '../../components/TextAlert';
import { useSessionContext } from '../../contexts/SessionContext';

enum Steps {
  Step1 = 1,
  Step2 = 2,
}

export const AccountEditPhoto: React.VFC = () => {
  const [session, setSession] = useSessionContext();
  const accountDetailsQuery = useQuery(['account-details'], () =>
    getAccountDetails()
  );
  const [step, setStep] = useState<Steps>(Steps.Step1);
  const [newImageScale, setNewImageScale] = useState(1);
  const [newImageFile, setNewImageFile] = useState<File>();
  const [error, setError] = useState<string>();
  const [done, setDone] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<AvatarEditor>(null);
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const chosenFile = event.target.files && event.target.files[0];

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (chosenFile == null) {
      setError('Failed to load the image');
      return;
    }

    setNewImageFile(chosenFile);
    setStep(Steps.Step2);
  };

  const handleUploadFileClick = () => {
    fileInputRef.current?.click();
  };

  const onCancelClicked = () => {
    setError(undefined);
    setNewImageFile(undefined);
    setNewImageScale(1);
    setStep(Steps.Step1);
  };

  const onSaveClicked = async () => {
    setError(undefined);

    const newScaledImageFile = await new Promise<Blob | null>(
      (resolve): void => {
        const editor = editorRef.current;

        if (editor == null) {
          resolve(null);
          return;
        }

        const canvasScaled = editor.getImageScaledToCanvas();
        canvasScaled.toBlob((blob) => resolve(blob));
      }
    );

    if (newScaledImageFile == null) {
      setError('Please select a photo to upload');
      return;
    }

    try {
      const uploadResult = await uploadAccountPhoto({
        photoFile: newScaledImageFile,
      });

      if (uploadResult) {
        setSession({
          ...session,
          accountDetails: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...session.accountDetails!,
            avatarUrl: uploadResult.photoUrl,
          },
        });
        setDone(true);
      }
    } catch (err) {
      setError('Error uploading account photo. Ensure the file is under 2MB.');
    }
  };

  if (done) {
    return <Redirect to="/account" />;
  }

  if (accountDetailsQuery.isLoading) {
    return <span>Loading...</span>;
  }

  if (accountDetailsQuery.isError) {
    return (
      <TextAlert
        label={
          (accountDetailsQuery.error as Error).message ||
          'Unexpected error occurred loading your account'
        }
      />
    );
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { route: '/account', label: 'My Account' },
          { label: 'Edit Account Photo' },
        ]}
      />

      <div className="bg-white">
        <Heading title="Edit Account Photo">
          {newImageFile && (
            <>
              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onCancelClicked}
              >
                Reset
              </button>

              <button
                type="button"
                className="p-3 text-lg w-20 hover:bg-white hover:text-blue-500"
                onClick={onSaveClicked}
              >
                Save
              </button>
            </>
          )}
        </Heading>

        {error && (
          <div className="flex text-red-500 bg-red-200 m-3 border-red-400 border p-4">
            <div className="flex-1">{error}</div>
            <button
              type="button"
              title="Clear error"
              className="cursor-pointer hover:text-white h-full w-10 flex justify-center items-center"
              onClick={() => setError(undefined)}
            >
              <XIcon height={24} />
            </button>
          </div>
        )}

        {step === Steps.Step1 && (
          <>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg, image/png"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <button type="button" onClick={handleUploadFileClick}>
              Upload File
            </button>
          </>
        )}

        {step === Steps.Step2 && newImageFile && (
          <div className="relative max-w-min border">
            <AvatarEditor
              ref={editorRef}
              image={newImageFile}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={newImageScale}
              rotate={0}
            />
            <div className="absolute bottom-0 right-0 flex flex-col">
              <button
                type="button"
                className="border flex justify-center items-center bg-white w-10 h-10 hover:text-blue-500"
                onClick={() => setNewImageScale((x) => x + 0.1)}
              >
                <PlusIcon width={21} />
              </button>
              <button
                type="button"
                className="border flex justify-center items-center bg-white w-10 h-10 hover:text-blue-500"
                onClick={() => setNewImageScale((x) => x - 0.1)}
              >
                <MinusIcon width={21} />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
