export type LoginGoogleSuccessResult = {
  accessToken: string;
  emailVerified: boolean;
  mobileVerified: boolean;
};

export type LoginGoogleSuccessResponse = {
  success: true;
  result: LoginGoogleSuccessResult;
};

export type LoginGoogleErrorResponse = {
  success: false;
  errorMessage: string;
};

export type LoginGoogleResponse =
  | LoginGoogleSuccessResponse
  | LoginGoogleErrorResponse;

const errorMessages = new Map<number, string>([
  [401, 'Error logging in with your Google account'],
  [500, 'An unexpected error occurred'],
]);

export async function loginWithGoogle(
  googleAccessToken: string
): Promise<LoginGoogleResponse> {
  try {
    const loginResult = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login/google`,
      {
        method: 'POST',
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${googleAccessToken}`,
        },
      }
    );

    if (loginResult.status !== 200) {
      return {
        success: false,
        errorMessage:
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          errorMessages.get(loginResult.status) ?? errorMessages.get(500)!,
      };
    }

    return {
      success: true,
      result: await loginResult.json(),
    };
  } catch (ex) {
    return {
      success: false,
      errorMessage:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        errorMessages.get(500)!,
    };
  }
}
