import axios from 'axios';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1/`,
});

client.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `bearer ${localStorage.getItem(
      'access_token'
    )}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const apiClient = client;
