import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type RepublishListingPosterRequest = {
  listingId: string;
};

export async function republishListingPoster(
  request: RepublishListingPosterRequest
): Promise<void> {
  try {
    await apiClient.post(`listing/${request.listingId}/republish-poster`);
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
