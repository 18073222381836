import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ChangeListingPhotosPositionsRequest } from './api-types';
import { ValidationError } from './common';

export async function changeListingPhotosPositions(
  listingId: string,
  request: ChangeListingPhotosPositionsRequest
): Promise<void> {
  try {
    await apiClient.post<void>(`listing/${listingId}/photos/positions`, {
      listingPhotosPositions: request.listingPhotosPositions,
    });
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
