import React from 'react';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Session } from '../../contexts/SessionContext';
import { AccountDetails } from './AccountDetails';
import { AccountEditPhoto } from './AccountEditPhoto';

export const getAuthRoutes = (session: Session): React.ReactNode[] => [
  <ProtectedRoute
    exact
    path="/account"
    component={AccountDetails}
    session={session}
  />,
  <ProtectedRoute
    exact
    path="/account/edit-photo"
    component={AccountEditPhoto}
    session={session}
  />,
];
