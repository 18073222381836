import { Dialog } from './Dialog';

export type DialogConfirmProps = {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

export const DialogConfirm: React.FC<DialogConfirmProps> = ({
  open,
  onClose,
  title,
  children,
  onConfirm,
}: DialogConfirmProps) => {
  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <h2 className="text-xl">{title}</h2>
      <div className="py-5">{children}</div>
      <div className="flex justify-end">
        <div className="p-1">
          <button
            type="button"
            onClick={() => onClose()}
            className="bg-red-500 hover:bg-red-300 text-white p-2 px-6 rounded"
          >
            No
          </button>
        </div>
        <div className="p-1">
          <button
            type="button"
            className="bg-green-500 hover:bg-green-300 text-white p-2 px-6 rounded"
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Dialog>
  );
};
