import { apiClient } from './api-client';
import { PropertyAddress } from './api-types';

export async function getAddresses(
  searchText: string
): Promise<PropertyAddress[]> {
  const address = encodeURIComponent(searchText);
  const result = await apiClient.get<PropertyAddress[]>(`address/${address}`);

  return result.data;
}
