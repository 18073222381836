import { Link } from 'react-router-dom';

type NavigationMenuItemProps = {
  children: React.ReactNode;
  to: string;
};

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
  children,
  to,
}: NavigationMenuItemProps) => {
  return (
    <Link
      className="text-gray-900 hover:bg-blue-500 hover:text-white group flex rounded-md items-center w-full px-2 py-2 text-sm hover:no-underline"
      to={to}
    >
      {children}
    </Link>
  );
};

export const NavigationMenu: React.VFC = () => {
  return (
    <>
      <NavigationMenuItem to="/listings">Listings</NavigationMenuItem>
      <NavigationMenuItem to="/account">My Account</NavigationMenuItem>
    </>
  );
};
