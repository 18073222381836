import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { UploadListingPhotosSuccessResult } from './api-types';
import { ValidationError } from './common';

export type UploadListingPhotosRequest = {
  listingId: string;
  photoFiles: File[];
};

export type Photo = {
  photoId: string;
  photoName: string;
  photoSize: number;
  photoUrl: string;
};

export async function uploadListingPhotos(
  request: UploadListingPhotosRequest
): Promise<UploadListingPhotosSuccessResult | undefined> {
  try {
    const data = new FormData();
    request.photoFiles.forEach((photoFile) => {
      data.append('files', photoFile);
    });

    const response = await apiClient.post<UploadListingPhotosSuccessResult>(
      `listing/${request.listingId}/photos`,
      data
    );
    return response.data;
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
