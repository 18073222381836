/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useQuery } from 'react-query';
import Downshift from 'downshift';
import { XIcon, SearchIcon } from '@heroicons/react/outline';
import { getAddresses, PropertyAddress } from '../api';
import { TextAlert } from './TextAlert';

export type AddressSelectProps = {
  id: string;
  address: PropertyAddress | undefined;
  addressChosen: (address: PropertyAddress | undefined) => void;
};

export const AddressSelect: React.VFC<AddressSelectProps> = ({
  id,
  address,
  addressChosen,
}: AddressSelectProps) => {
  const [addressText, setAddressText] = useState('');
  const { isLoading, isError, data } = useQuery(
    ['addresses', addressText],
    () =>
      addressText.trim().length === 0 ? [] : getAddresses(addressText.trim())
  );

  return (
    <>
      <Downshift<PropertyAddress>
        onChange={(selectedItem) => addressChosen(selectedItem || undefined)}
        onInputValueChange={(inputText) => setAddressText(inputText)}
        itemToString={(item) => (item ? item.label : '')}
        initialInputValue={address?.label}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          clearSelection,
        }) => (
          <div>
            <div className="relative">
              {!isLoading && (
                <span className="absolute left-2 top-1/2 transform -translate-y-1/2">
                  <SearchIcon height={20} className="text-gray-500" />
                </span>
              )}
              {isLoading && (
                <span className="absolute left-2 top-1/2 transform -translate-y-1/2">
                  <svg
                    className="animate-spin h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </span>
              )}
              <input
                {...getInputProps({ id })}
                className="block p-2 px-8 border w-full"
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 hover:text-blue-500"
                onClick={() => {
                  clearSelection();
                }}
                disabled={isLoading}
              >
                <XIcon height={20} />
              </button>
            </div>

            {isOpen && (data != null || isError) && (
              <div
                {...getMenuProps()}
                className="p-2 bg-white border"
                style={{ marginTop: -1 }}
              >
                {data &&
                  data.map((item, index) => (
                    <button
                      type="button"
                      {...getItemProps({
                        key: item.id,
                        item,
                        index,
                      })}
                      className="block p-1 text-gray-900 hover:bg-blue-500 hover:text-white text-left"
                    >
                      {item.label}
                    </button>
                  ))}
                {data && data.length === 0 && (
                  <TextAlert label="No address found&hellip;" />
                )}
                {isError && (
                  <TextAlert label="Error searching for the address" />
                )}
              </div>
            )}
          </div>
        )}
      </Downshift>
    </>
  );
};
