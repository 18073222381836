import { useEffect, useMemo, useState } from 'react';
import { Menu } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { NavigationMenu } from './NavigationMenu';

type UserButtonProps = {
  userSymbol: string;
  userFullName: string;
};

const UserButton = ({ userSymbol, userFullName }: UserButtonProps) => {
  return (
    <div
      className="flex items-center whitespace-nowrap"
      data-testid="user-button"
    >
      <div className="h-6 w-6 text-xs rounded-full flex items-center justify-center border text-white border-white bg-gray-500">
        <span>{userSymbol}</span>
      </div>
      <span className="px-2 text-xs">{userFullName}</span>
    </div>
  );
};

export interface User {
  firstName: string;
  lastName: string;
}

export interface HeaderProps {
  user: User;
  logoutClick: VoidFunction;
}

export const Header: React.VFC<HeaderProps> = ({
  user,
  logoutClick,
}: HeaderProps) => {
  const userSymbol = useMemo(
    () => user && (user.firstName[0] + user.lastName[0]).toLocaleUpperCase(),
    [user]
  );
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setShowMenu(false);
    });
  }, [history]);

  const userButton = (
    <UserButton
      userSymbol={userSymbol}
      userFullName={`${user.firstName} ${user.lastName}`}
    />
  );

  return (
    <>
      <nav
        className="flex items-center text-white bg-gray-500"
        style={{ borderBottomColor: '#cbd9e3', borderBottomWidth: 1 }}
      >
        <div className="flex-1 p-4 uppercase whitespace-nowrap">
          SquareMeter{' '}
          <span className=" p-1 px-3 ml-3 rounded bg-white text-gray-500">
            BETA
          </span>
        </div>

        <div className="sm:hidden">
          <button
            type="button"
            className="text-white p-4"
            onClick={() => setShowMenu((x) => !x)}
          >
            {!showMenu && <MenuIcon height={32} />}
            {showMenu && <XIcon height={32} />}
          </button>
        </div>

        <div className="hidden sm:block">
          {/* User Button */}
          <Menu as="div" className="relative">
            <Menu.Button>{userButton}</Menu.Button>

            <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="p-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={`${
                        active ? 'bg-blue-500 text-white' : 'text-gray-900'
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                      onClick={logoutClick}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </nav>

      {showMenu && (
        <div className="sm:hidden text-gray-700 border-b">
          <div className="m-4">
            <div className="pb-3">{userButton}</div>
            <div>
              <button
                type="button"
                className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                onClick={logoutClick}
              >
                Logout
              </button>
            </div>
          </div>
          <div className="border-b mx-2" />
          <div className="m-4" role="navigation">
            <NavigationMenu />
          </div>
        </div>
      )}
    </>
  );
};
