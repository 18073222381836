import React, { useEffect, useMemo } from 'react';
import { ListingDetails } from '../../../api';

export type FormData = {
  listingTitle: string;
};

export type FormProps = {
  listing: ListingDetails;
  formData: FormData;
  onFormDataChange: (value: FormData) => void;
  onFormChangesChange: (value: Map<keyof FormData, string>) => void;
};

export const Form: React.VFC<FormProps> = ({
  listing,
  formData,
  onFormDataChange,
  onFormChangesChange,
}: FormProps) => {
  const changes = useMemo(() => {
    const changesMap = new Map<keyof FormData, string>();
    if (listing.listingTitle !== formData.listingTitle) {
      changesMap.set('listingTitle', formData.listingTitle);
    }
    return changesMap;
  }, [listing, formData]);

  useEffect(() => {
    const newFormData: FormData = {
      listingTitle: listing.listingTitle || '',
    };

    onFormDataChange(newFormData);
  }, [listing, onFormDataChange]);

  useEffect(() => {
    onFormChangesChange(changes);
  }, [changes, onFormChangesChange]);

  const onlistingTitleInput = (evt: React.FormEvent<HTMLInputElement>) => {
    const newValue = (evt.target as HTMLInputElement).value;

    const newFormData: FormData = {
      ...formData,
      listingTitle: newValue,
    };

    onFormDataChange(newFormData);
  };

  return (
    <>
      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="listingTitle">Listing Title</label>
        <input
          name="listingTitle"
          type="text"
          className="block p-2 border w-full"
          value={formData.listingTitle}
          onInput={onlistingTitleInput}
        />
      </div>

      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="listingTitle">Listing Type</label>
        <input
          name="listingType"
          type="text"
          className="block p-2 border w-full"
          value={listing.listingType}
          disabled
        />
      </div>

      <div className="my-3 w-full md:w-1/2">
        <label htmlFor="listingStatus">Listing Status</label>
        <input
          name="listingStatus"
          type="text"
          className="block p-2 border w-full"
          value={listing.listingStatus}
          disabled
        />
      </div>
      {/* <div>
        changes:{' '}
        {JSON.stringify(Object.fromEntries(changes.entries()), undefined, 2)}
      </div> */}
    </>
  );
};
