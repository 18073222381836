import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type UploadAccountPhotoRequest = {
  photoFile: File | Blob;
};

export type UploadAccountPhotoSuccessResult = {
  photoUrl: string;
};

export async function uploadAccountPhoto(
  request: UploadAccountPhotoRequest
): Promise<UploadAccountPhotoSuccessResult | undefined> {
  try {
    const data = new FormData();
    data.append('file', request.photoFile);

    const response = await apiClient.post<UploadAccountPhotoSuccessResult>(
      'account/photo',
      data
    );
    return response.data;
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
