import { LoginAccountSuccessResult } from './api-types';

export type LoginAccountSuccessResponse = {
  success: true;
  result: LoginAccountSuccessResult;
};

export type LoginAccountErrorResponse = {
  success: false;
  errorMessage: string;
};

export type LoginAccountResponse =
  | LoginAccountSuccessResponse
  | LoginAccountErrorResponse;

const errorMessages = new Map<number, string>([
  [401, 'Invalid username and password combination'],
  [429, 'Too many unsuccessful attempts, please wait some time and try again'],
  [500, 'An unexpected error occurred'],
]);

export async function login(
  username: string,
  password: string
): Promise<LoginAccountResponse> {
  try {
    const loginResult = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          password,
        }),
      }
    );

    if (loginResult.status !== 200) {
      return {
        success: false,
        errorMessage:
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          errorMessages.get(loginResult.status) ?? errorMessages.get(500)!,
      };
    }

    return {
      success: true,
      result: await loginResult.json(),
    };
  } catch (ex) {
    return {
      success: false,
      errorMessage:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        errorMessages.get(500)!,
    };
  }
}
