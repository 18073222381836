import { Redirect, Route, Switch } from 'react-router-dom';
import { useMemo } from 'react';
import { useSessionContext } from './contexts/SessionContext';
import { AccountType } from './api';
import { getSellerRoutes } from './pages/Sellers/Routes';
import { authRoutes } from './pages/Auth/Routes';
import { getAuthRoutes } from './pages/Account/Routes';
import { getBuyerRoutes } from './pages/Buyers/Routes';

export const AppRoutes: React.VFC = () => {
  const [session] = useSessionContext();
  const isBuyer = useMemo(
    () => session.accountDetails?.accountType === AccountType.User,
    [session]
  );
  const isSeller = useMemo(
    () =>
      session.accountDetails != null &&
      session.accountDetails.accountType !== AccountType.User,
    [session]
  );

  return (
    <Switch>
      {isSeller && getSellerRoutes(session)}
      {isBuyer && getBuyerRoutes(session)}
      {getAuthRoutes(session)}
      {authRoutes}

      {/* Redirect to default route */}
      <Route path="*">
        {isSeller && <Redirect to="/listings" />}
        {isBuyer && <Redirect to="/listings" />}
        {isSeller === false && isBuyer === false && <Redirect to="/account" />}
      </Route>
    </Switch>
  );
};
