import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getListingDetails } from '../../../api';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { TextAlert } from '../../../components/TextAlert';
import { DocumentsEditor } from './DocumentsEditor';

export const Layout: React.VFC = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const { isLoading, isError, error, data, refetch } = useQuery(
    ['listing-details', listingId],
    () => getListingDetails(listingId)
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return (
      <TextAlert
        label={
          (error as Error).message ||
          'Unexpected error occurred loading the listing'
        }
      />
    );
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { route: '/listings', label: 'Listings' },
          {
            route: `/listings/${listingId}`,
            label:
              data != null ? data.property.propertyAddress : 'Listing Details',
          },
          { label: 'Edit Documents' },
        ]}
      />

      {data && (
        <DocumentsEditor
          listingId={listingId}
          documents={data.documents}
          changed={async () => refetch()}
        />
      )}
    </>
  );
};
