import { AxiosError } from 'axios';
import { apiClient } from './api-client';
import { ValidationError } from './common';

export type UploadListingDocumentsRequest = {
  listingId: string;
  files: File[];
};

export type Document = {
  documentId: string;
  documentName: string;
  documentSize: number;
  documentUrl: string;
};

export type UploadListingDocumentsSuccessResult = {
  documents: Document[];
};

export async function uploadListingDocuments(
  request: UploadListingDocumentsRequest
): Promise<UploadListingDocumentsSuccessResult | undefined> {
  try {
    const data = new FormData();
    request.files.forEach((file) => {
      data.append('files', file);
    });

    const response = await apiClient.post<UploadListingDocumentsSuccessResult>(
      `listing/${request.listingId}/documents`,
      data
    );
    return response.data;
  } catch (err) {
    if ((err as AxiosError).isAxiosError) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 400) {
        // standard model validation error
        if (axiosError.response.data.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.errors
          );
        }
        // problem details validation error
        if (axiosError.response.data.value.errors) {
          throw new ValidationError(
            'Invalid Request',
            axiosError.response.data.value.errors
          );
        }
      }
    }
    throw err;
  }
}
