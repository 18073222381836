import { FolderAddIcon } from '@heroicons/react/outline';
import { ChangeEvent, DragEvent, useMemo, useRef, useState } from 'react';

export type FileDropZoneProps = {
  label: string;
  fileTypes: Array<{
    label: string;
    mimeType: string;
  }>;
  filesAdded: (files: File[]) => void;
};

export const FileDropZone: React.VFC<FileDropZoneProps> = ({
  label,
  fileTypes,
  filesAdded,
}: FileDropZoneProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const allowedMimeTypes = useMemo(
    () => fileTypes.map((x) => x.mimeType.toLowerCase()),
    [fileTypes]
  );
  const allowedMimeTypesString = useMemo(
    () => allowedMimeTypes.join(', '),
    [allowedMimeTypes]
  );
  const [draggedOnto, setDraggedOnto] = useState(false);
  const [disallowedFileTypeDragged, setDisallowedFileTypeDragged] = useState<
    string[]
  >([]);

  const onClick = () => {
    fileInputRef.current?.click();
  };

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggedOnto(true);
  };
  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggedOnto(true);
  };

  const onDragLeave = () => {
    setDraggedOnto(false);
    setDisallowedFileTypeDragged([]);
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggedOnto(false);

    const { files } = event.dataTransfer;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const addedFiles = Array.from(files!);

    const anyDisallowedFiles = addedFiles.filter(
      (file) => allowedMimeTypes.indexOf(file.type.toLowerCase()) === -1
    );

    if (anyDisallowedFiles.length === 0) {
      setDisallowedFileTypeDragged([]);
      filesAdded(addedFiles);
    } else {
      setDisallowedFileTypeDragged(anyDisallowedFiles.map((x) => x.type));
    }
  };

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const addedFiles = Array.from(files!);
    filesAdded(addedFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div
      role="none"
      className={`h-full w-full border-4 border-dashed cursor-pointer text-gray-400 ${
        disallowedFileTypeDragged.length === 0 &&
        'hover:text-blue-400 hover:border-blue-400'
      } ${
        draggedOnto && disallowedFileTypeDragged.length === 0
          ? 'text-blue-400 border-blue-400'
          : ''
      } ${
        disallowedFileTypeDragged.length !== 0
          ? 'text-red-500 border-red-400'
          : ''
      }`}
      onClick={onClick}
      onKeyPress={onClick}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="h-full flex flex-col justify-center items-center text-center pointer-events-none">
        {disallowedFileTypeDragged.length !== 0 && (
          <div className="bg-red-100 h-full w-full flex flex-col justify-center items-center">
            <div>
              The file type &quot;{disallowedFileTypeDragged.join(', ')}&quot;
              is not permitted.
            </div>
            <button
              type="button"
              className="block w-1/2 text-center border p-1 m-4 bg-white text-gray-900 hover:bg-blue-500 hover:text-white text-left pointer-events-auto"
              onClick={(event) => {
                event.stopPropagation();
                setDisallowedFileTypeDragged([]);
              }}
            >
              OK
            </button>
          </div>
        )}

        {disallowedFileTypeDragged.length === 0 && (
          <>
            <FolderAddIcon height={90} />

            <div className="flex space-x-3 font-mono font-bold">
              {fileTypes.map((fileType) => (
                <div key={fileType.mimeType}>{fileType.label}</div>
              ))}
            </div>

            <p className="p-3">
              You can upload {label} by clicking here, or dragging {label} here.
            </p>
          </>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept={allowedMimeTypesString}
        multiple
        style={{ display: 'none' }}
        onChange={onFileInputChange}
      />
    </div>
  );
};
