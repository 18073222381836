/* eslint-disable no-debugger */
import { FormEvent, useState } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { getAccountDetails, provideMobile, ValidationError } from '../../api';
import { useSessionContext } from '../../contexts/SessionContext';
import {
  AccountOnboardingStatus,
  getAccountOnboardingStatus,
} from '../../helpers/onboarding-status';

export const ProvideMobile: React.VFC = () => {
  const [session, setSession] = useSessionContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setError(undefined);

    ReactGA.event({
      category: 'Auth',
      action: 'Provide Mobile Clicked',
    });

    const mobile = (
      (event.target as HTMLFormElement).elements.namedItem(
        'mobile'
      ) as HTMLInputElement
    ).value;

    try {
      await provideMobile({ mobile });

      const accountDetails = await getAccountDetails();
      localStorage.setItem('account_details', JSON.stringify(accountDetails));
      setSession((s) => ({
        ...s,
        accountDetails,
      }));

      if (accountDetails.userDetails.mobile != null) {
        ReactGA.event({
          category: 'Auth',
          action: 'Mobile Provided',
        });
      }
    } catch (err) {
      setError((err as ValidationError).message);
    } finally {
      setLoading(false);
    }
  };

  if (session.isAuthenticated === false || session.accountDetails == null) {
    return <Redirect to="/login" />;
  }

  const accountOnboardingStatus = getAccountOnboardingStatus(
    session.accountDetails
  );

  if (accountOnboardingStatus === AccountOnboardingStatus.Complete) {
    return <Redirect to={session.redirectPath} />;
  }

  if (
    accountOnboardingStatus ===
    AccountOnboardingStatus.EmailVerificationRequired
  ) {
    return <Redirect to="/verify-email" />;
  }

  if (
    accountOnboardingStatus ===
    AccountOnboardingStatus.MobileVerificationRequired
  ) {
    return <Redirect to="/verify-mobile" />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-96 pt-20">
        <div className="text-3xl text-center py-1">Almost Done!</div>
        <div className="text-2xl text-center py-1">
          Enter your mobile number.
        </div>
        <div className="text-lg text-center py-10">
          We will send you a verification code to your mobile number.
        </div>
        <form onSubmit={onSubmit} autoComplete="off">
          <label htmlFor="mobile" className="block m-2">
            Mobile
            <input
              id="mobile"
              name="mobile"
              type="text"
              autoComplete="off"
              className="p-1 border block w-full"
              disabled={loading}
            />
          </label>
          {error && (
            <div className="p-2 m-2 bg-red-400 text-white">{error}</div>
          )}
          <div className="m-2">
            <button
              type="submit"
              className="block bg-gray-700 text-white w-full p-2"
              disabled={loading}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
