import { AccountQueryResult } from '../api';

export enum AccountOnboardingStatus {
  EmailVerificationRequired = 'email-verification-required',
  MobileRequired = 'mobile-required',
  MobileVerificationRequired = 'mobile-verification-required',
  Complete = 'complete',
}

export const getAccountOnboardingStatus = (
  account: AccountQueryResult
): AccountOnboardingStatus => {
  if (account.userDetails.emailVerified === false) {
    return AccountOnboardingStatus.EmailVerificationRequired;
  }

  if (account.userDetails.mobile == null) {
    return AccountOnboardingStatus.MobileRequired;
  }

  if (account.userDetails.mobileVerified === false) {
    return AccountOnboardingStatus.MobileVerificationRequired;
  }

  return AccountOnboardingStatus.Complete;
};
